<template>
  <div class="OrderedList">
    <div class="OrderedList__header">
      <div class="OrderedList__list-number" v-if="listNumber">
        {{ listNumber }}.
      </div>
      <h3 class="OrderedList__title">
        {{ title }}
      </h3>
    </div>
    <ol class="OrderedList__list">
      <OrderedListItem
          v-for="(rule, index) in list"
          :key="index"
          :content="rule.content || rule"
          :description="rule.description"
          :marker="getItemMarker(index)"
          :isShow="!rule.deprecated || rule.deprecated !== true"
      />
    </ol>
  </div>
</template>

<script>
import OrderedListItem from "@/components/OrderedListItem";
import {alphabet_ru} from "@/const/alphabet_ru";

export default {
  name: "OrderedList",
  components: {OrderedListItem},
  props: {
    title: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
    listIndex: {
      type: Number,
    },
  },
  computed: {
    isAlphabeticallyType() {
      return this.listIndex == null;
    },
    listNumber() {
      if (!this.isAlphabeticallyType) {
        return this.listIndex + 1;
      }
      return null;
    },
  },
  methods: {
    getItemMarker(index) {
      if (this.isAlphabeticallyType) {
        const character = alphabet_ru[index % alphabet_ru.length];
        return `${character})`;
      } else {
        return `${this.listNumber}.${index + 1}`;
      }
    },
  },
}
</script>

<style lang="scss">
.OrderedList {

  & + & {
    padding-top: 48px;

    @include mobile {
      padding-top: 40px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    color: $white;

    @include mobile {
      font-size: 18px;
    }
  }

  &__list-number {
    width: 40px;

    @include mobile {
      width: 30px;
    }
  }

  &__title {
    margin: 0;
  }
}
</style>
