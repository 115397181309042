import Vue from 'vue';
import Vapi from "vuex-rest-api"

export default new Vapi({
    axios: Vue.axios,
    state: {
        payments: []
    }
})
    .get({
        action: 'getPayments',
        property: 'payments',
        path: '/orders/methods/',
        onSuccess(s, {data}) {
            s.payments = data;
        }
    })
    .get({
        action: 'getOrder',
        property: 'order',
        path: (data) => `/orders/${data}/`,
        onSuccess(s, {data}) {
            s.order = data;
        }
    })
    .getStore({namespaced: true});
