<template>
  <div class="AppLayout">
    <div class="container">
      <div class="AppLayout__content">
        <div class="AppLayout__main-content">
          <Header/>
          <slot/>
        </div>
        <Footer/>
      </div>
    </div>
    <NotificationsManagerHolder/>
  </div>
</template>

<script>
import Header from "@/modules/app/Header";
import Footer from "@/modules/app/Footer";
import NotificationsManagerHolder from "../components/NotificationsManagerHolder";

export default {
  name: "AppLayout",
  components: {NotificationsManagerHolder, Footer, Header}
}
</script>

<style lang="scss">
.AppLayout {

  &__content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
