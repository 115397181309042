<template>
  <AppLayout>
    <div class="RulesPage">
      <div class="RulesPage__content">
        <h1 class="RulesPage__title">Правила сервера 📜</h1>
        <div class="RulesPage__aside">
          <div class="RulesPage__date-updated">
            Последняя редакция:<br>
            {{ rules.update }}
          </div>
          <PageNav class="RulesPage__page-nav" :list="titles"/>
        </div>
        <OrderedList
            :title="rules.main.title"
            :list="rules.main.list"
        />
        <OrderedList
            v-for="({title, list}, index) in rules.rules"
            :key="index"
            :list-index="index"
            :title="title"
            :list="list"
            :id="title"
        />
        <div class="RulesPage__about-rules">
          <p v-for="(text, index) in rules.etc" :key="index">
            {{ text }}
          </p>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import OrderedList from "@/components/OrderedList";
import AppLayout from "@/layouts/AppLayout";

import PageNav from "@/components/PageNav";
import {mapActions, mapState} from "vuex";

export default {
  name: "RulesPage",
  components: {PageNav, AppLayout, OrderedList},
  computed: {
    ...mapState('rules', ['rules', 'titles'])
  },
  methods: {
    ...mapActions('rules', ['getRules'])
  },
  created() {
    if (!this.rules?.length) {
      this.getRules();
    }
  }
}
</script>

<style lang="scss">
.RulesPage {
  padding-bottom: 16px;
  position: relative;

  @include mobile {
    padding-bottom: 40px;
  }

  &__content {
    max-width: 624px;
    width: 100%;
  }

  &__aside {
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    width: 224px;
    padding-top: 14px;

    @include laptop {
      position: static;

      width: auto;
      padding-top: 0;
      margin-bottom: 24px;
    }
  }

  &__page-nav {
    padding-top: 32px;
    position: sticky;
    top: 0;

    @include laptop {
      padding-top: 20px;
      position: static;
    }
  }

  &__title {
    margin-bottom: 48px;

    @include mobile {
      margin-bottom: 8px;
    }
  }

  &__date-updated {
    margin-bottom: 26px;

    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    color: $textGray;

    @include laptop {
      margin-bottom: 20px;

      br {
        display: none;
      }
    }
  }

  &__about-rules {
    padding-top: 40px;
    margin-top: 40px;

    border-top: 1px solid rgba($white, 0.12);

    @include mobile {
      padding-top: 20px;
      margin-top: 20px;
    }

    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      color: $textLightGray;
    }

    p + p {
      margin-top: 20px;
    }
  }
}
</style>
