<template>
  <HowToDoModal
      title="Как купить донат на ИнМайне? 👑"
      :open="open"
      @close="$emit('close')"
  >
    <div class="HowToDonateModal">
      <HowToDoModalStep
          :number="1"
          :steps="5"
          title="Введите свой никнейм на сервере и нажмите «Выбрать привилегию»"
          wide-image
          first
          v-model="activeIndex"
      >
        <template #image>
          <img class="hide-mobile" src="@/assets/images/instruction/how-to-donate-img-1.jpg" alt="">
          <img class="hide-pc" src="@/assets/images/instruction/how-to-donate-mobile-img-1.jpg" alt="">
        </template>
      </HowToDoModalStep>
      <HowToDoModalStep
          :number="2"
          :steps="5"
          title="Из списка выберите сервер, на котором хотите купить донат и нажмите на него"
          wide-image
          v-model="activeIndex"
      >
        <template #image>
          <img class="hide-mobile" src="@/assets/images/instruction/how-to-donate-img-2.jpg" alt="">
          <img class="hide-pc" src="@/assets/images/instruction/how-to-donate-mobile-img-2.jpg" alt="">
        </template>
      </HowToDoModalStep>
      <HowToDoModalStep
          :number="3"
          :steps="5"
          title="Выберите донат, нажмите кнопку «Купить»"
          v-model="activeIndex"
      >
        <template #image>
          <img class="hide-mobile" src="@/assets/images/instruction/how-to-donate-img-3.jpg" alt="">
          <img class="hide-pc" src="@/assets/images/instruction/how-to-donate-mobile-img-3.jpg" alt="">
        </template>
        <template #donate>
          <p>Если у вас уже есть привилегия, то цены показываются с учетом доплаты и скидок.</p>
        </template>
      </HowToDoModalStep>
      <HowToDoModalStep
          :number="4"
          :steps="5"
          title="Выберите способ оплаты"
          v-model="activeIndex"
      >
        <template #image>
          <img class="hide-mobile" src="@/assets/images/instruction/how-to-donate-payments.png" alt="">
          <img class="hide-pc" src="@/assets/images/instruction/how-to-donate-payments.png" alt="">
        </template>
        <template #donate>
          <p>Можно оплатить картой VISA, MASTERCARD, МИР. А так же электронными кошельками или с баланса телефона.</p>
        </template>
      </HowToDoModalStep>
      <HowToDoModalStep
          :number="5"
          :steps="5"
          title="Готово! На всякий случай, сделайте скриншот чека"
          last
          v-model="activeIndex"
          @close="$emit('close')"
      >
        <template #image>
          <img class="hide-mobile" src="@/assets/images/instruction/how-to-donate-img-5.jpg" alt="">
          <img class="hide-pc" src="@/assets/images/instruction/how-to-donate-mobile-img-5.jpg" alt="">
        </template>
        <template #donate>
          <p>Если будут проблемы и вы не получите донат, напишите нам и приложите этот скриншот — все исправим.</p>
        </template>
      </HowToDoModalStep>
    </div>
  </HowToDoModal>
</template>

<script>

import HowToDoModal from "@/components/HowToDoModal";
import HowToDoModalStep from "@/components/HowToDoModalStep";
import {instructionModal} from "@/mixins/instructionModal";

export default {
  name: "HowToDonateModal",
  components: {HowToDoModalStep, HowToDoModal},
  mixins: [instructionModal()],
}
</script>

<style lang="scss">
.HowToDonateModal {

}
</style>