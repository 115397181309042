<template>
  <component
      :is="back ? 'div' : 'RouterLink'"
      :to="{name: to}"
      @click="handleClick"
      class="NavBack"
  >
    <ArrowBackIcon/>
    <span class="NavBack__text">
      <slot/>
    </span>
  </component>
</template>

<script>

import ArrowBackIcon from '@/assets/images/nav-arrow-back.svg?inline'

export default {
  name: "NavBack",
  components: {
    ArrowBackIcon,
  },
  props: {
    to: {
      type: String,
      default: 'home',
    },
    back: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleClick() {
      if(this.back) {
        this.$router.back();
      }
    }
  },
}
</script>

<style lang="scss">
.NavBack {
  position: absolute;
  top: -6px;
  transform: translateY(-100%);
  padding: 8px 0;

  display: flex;
  align-items: center;

  cursor: pointer;
  color: $textGray;
  transition: color 250ms;

  svg {
    @include mobile {
      height: 13px;
      width: 13px;
      margin-top: -1px;
    }

    path {
      transition: fill 250ms;
    }
  }

  &:hover {
    color: $white;

    svg path {
      fill: $white;
    }
  }

  &:active {
    color: $textLightGray;

    svg path {
      fill: $textLightGray;
    }
  }

  &__text {
    padding-left: 10px;
    font-size: 18px;
    font-weight: 400;
    color: inherit;

    @include mobile {
      font-size: 13px;
    }
  }
}
</style>
