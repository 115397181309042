<template>
  <AppLayout>
    <div class="NotFoundPage">
      <h1>404</h1>
      <p>Страница не существует!</p>
      <router-link to="/">
        <ButtonSecondary>
          Перейти на главную
        </ButtonSecondary>
      </router-link>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import ButtonSecondary from "@/components/ButtonSecondary";

export default {
  name: "ContactsPage",
  components: {AppLayout, ButtonSecondary}
}
</script>

<style lang="scss">
.NotFoundPage {
  h1 {
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 30px;
  }
}
</style>