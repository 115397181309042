<template>
  <RouterLink class="LogoIcon" :to="{name: 'home'}">
    <LogoSvgIcon class="LogoIcon__icon" />
  </RouterLink>
</template>

<script>
import LogoSvgIcon from '@/assets/images/logo.svg?inline'

export default {
  name: "LogoIcon",
  components: {
    LogoSvgIcon
  }
}
</script>

<style lang="scss">
.LogoIcon {
  position: relative;
  z-index: 2;
  display: flex;

  &__icon {
    filter: drop-shadow(0px 28px 40px rgba(243, 63, 63, 0.8));
  }
}
</style>