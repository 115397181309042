<template>
  <div class="MainPageLayout">
    <AppLayout>
      <div class="MainPageLayout__slot-wrapper">
        <slot/>
      </div>
      <div
          class="MainPageLayout__content mobile-dark-block"
          :class="{'hide-mobile': hideMobile}"
      >
        <div class="MainPageLayout__content-row">
          <HowToDonate/>
          <HowToPlay/>
        </div>
        <PlayersReviews/>
        <div class="MainPageLayout__content-row">
          <ServersPlayersOnline/>
          <LastPurchasesPlayers/>
        </div>
      </div>
    </AppLayout>
  </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import HowToPlay from "@/modules/mainLayout/HowToPlay";
import HowToDonate from "@/modules/mainLayout/HowToDonate";
import PlayersReviews from "@/modules/mainLayout/PlayersReviews";
import ServersPlayersOnline from "@/modules/mainLayout/ServersPlayersOnline";
import LastPurchasesPlayers from "@/modules/mainLayout/LastPurchasesPlayers";
import {mapGetters} from "vuex";

export default {
  name: "MainPageLayout",
  components: {LastPurchasesPlayers, ServersPlayersOnline, PlayersReviews, HowToDonate, HowToPlay, AppLayout},
  props: {
    hideMobile: Boolean,
  },
  computed: {
    ...mapGetters('privileges', ['privileges']),
  },
}
</script>

<style lang="scss">
.MainPageLayout {
  background-image: url("~@/assets/images/bg/header-bg.png");
  background-size: contain;
  background-repeat: no-repeat;

  &__slot-wrapper {
    margin-top: 140px;
    position: relative;

    @include mobile {
      margin-top: 56px;
    }
  }

  &__content {
    margin-top: 170px;
    display: grid;
    grid-gap: 16px;

    @include mobile {
      padding: 0 20px;
      margin-top: 24px;
      grid-gap: 64px;
    }
  }

  &__content-row {
    display: grid;
    grid-template-columns: 1fr 40%;
    grid-gap: 16px;

    @include mobile {
      grid-template-columns: 1fr;
      grid-gap: 64px;
    }
  }
}
</style>
