<template>
  <HowToDoModal
      title="Как зайти на сервер? 🚩"
      :open="open"
      @close="$emit('close')"
  >
    <div class="HowToPlayModal">
      <HowToDoModalStep
          :number="0"
          :steps="4"
          hide-controller
          title="Нажмите на кнопку — сервер появится у вас в игре"
          v-model="activeIndex"
      >
        <template #main>
          <p class="HowToPlayModal__about">В списке серверов появится хаб — из хаба можно попасть на любой другой
            сервер</p>
          <AddServer>
            <Button>Добавить сервер</Button>
          </AddServer>
        </template>
        <template #image>
          <img src="@/assets/images/instruction/how-to-play-img-1.jpg" alt="">
        </template>
        <template #donate>
          <p>Так будет выглядеть список серверов, если все пройдет успешно</p>
        </template>
        <template #other>
          <div class="HowToPlayModal__add-manually">
            <p class="HowToPlayModal__add-manually-text">
              Если сервер не добавился,<br>
              добавьте его вручную
            </p>
            <ButtonSecondary class="hide-pc" @click="nextStep">
              Добавить по инструкции
            </ButtonSecondary>
          </div>
        </template>
      </HowToDoModalStep>
      <HowToDoModalStep
          :number="1"
          :steps="4"
          title="Зайдите в Майнкрафт и нажмите кнопку «Играть»"
          v-model="activeIndex"
      >
        <template #image>
          <img src="@/assets/images/instruction/how-to-play-img-2.jpg" alt="">
        </template>
        <template #donate>
          <p>Подойдет Майнкрафт ПЕ и<span class="nbsp"></span>БЕ любой версии от<span class="nbsp"></span>1.12 и выше</p>
        </template>
      </HowToDoModalStep>
      <HowToDoModalStep
          :number="2"
          :steps="4"
          title="Нажмите «Серверы», а затем «Добавить сервер»"
          v-model="activeIndex"
      >
        <template #image>
          <img src="@/assets/images/instruction/how-to-play-img-3.jpg" alt="">
        </template>
      </HowToDoModalStep>
      <HowToDoModalStep
          :number="3"
          :steps="4"
          title="Заполните форму как на картинке, нажмите  «Играть»"
          v-model="activeIndex"
      >
        <template #main>
          <HowToPlayAboutServer class="hide-pc"/>
        </template>
        <template #image>
          <img src="@/assets/images/instruction/how-to-play-img-4.jpg" alt="">
        </template>
        <template #donate>
          <HowToPlayAboutServer/>
        </template>
      </HowToDoModalStep>
      <HowToDoModalStep
          :number="4"
          :steps="4"
          title="Готово! Теперь ИнМайн в списке ваших серверов!"
          last
          v-model="activeIndex"
          @close="$emit('close')"
      >
        <template #image>
          <img src="@/assets/images/instruction/how-to-play-img-5.jpg" alt="">
        </template>
        <template #donate>
          <p>Так будет выглядеть список серверов, если все пройдет успешно</p>
        </template>
      </HowToDoModalStep>
    </div>
  </HowToDoModal>
</template>

<script>

import HowToDoModal from "@/components/HowToDoModal";
import HowToDoModalStep from "@/components/HowToDoModalStep";
import Button from "@/components/Button";
import ButtonSecondary from "@/components/ButtonSecondary";
import {instructionModal} from "@/mixins/instructionModal";
import HowToPlayAboutServer from "@/modules/modals/HowToPlayAboutServer";
import AddServer from "@/components/AddServer";

export default {
  name: "HowToPlayModal",
  components: {AddServer, HowToPlayAboutServer, ButtonSecondary, Button, HowToDoModalStep, HowToDoModal},
  mixins: [instructionModal(-1)],
  methods: {
    nextStep() {
      this.activeIndex = this.activeIndex + 1;
    },
  },
}
</script>

<style lang="scss">
.HowToPlayModal {

  &__about {
    margin-bottom: 24px;
  }

  &__add-manually {
    margin-top: 64px;
    padding-right: 16px;
    background-color: $bgDark;

    @include mobile {
      border-top: 1px solid rgba($white, .04);
      padding-top: 24px;
      margin-top: 24px;
      background: none;
    }
  }

  &__add-manually-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 120%;
    color: $white;

    @include mobile {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
    }
  }
}
</style>