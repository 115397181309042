<template>
  <div class="status-info">
    <div class="status" v-if="donate">
      Ваш статус сейчас: <span>{{ donate }}</span>
    </div>
    <div class="badges">
      <ButtonOutline class="discount" v-if="discount.small_message">{{discount.small_message}}</ButtonOutline>
<!--      <ButtonOutline class="price" v-if="donatePrice">Доплата <span>{{ donatePrice | price }}</span></ButtonOutline>-->
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import ButtonOutline from "@/components/ButtonOutline";

export default {
  name: "StatusInfo",
  components: {ButtonOutline},
  props: {
    donate: String,
  },
  computed: {
    ...mapGetters('privileges', ['privileges']),
    ...mapState('purchase', ['server']),
    ...mapState('discount', ['discount'])
  }
}
</script>

<style lang="scss">
.status-info {

  .status {
    font-size: 14px;
    line-height: 130%;
    color: $textGray;
    margin-bottom: 4px;

    span {
      color: $primary;
    }
  }

  .badges {
    display: flex;
    flex-wrap: wrap;
    margin: -2px;

    .ButtonOutline {
      margin: 2px;
      font-weight: normal;

      &.price {
        color: $textLightGray;

        span {
          display: inline-block;
          margin-left: 5px;
          color: $white;
        }
      }
    }
  }
}
</style>
