<template>
  <div class="dark-block">
    <div class="HowToPlay">
      <div class="HowToPlay__content">
        <h2 class="HowToPlay__title">
          Как зайти<br>
          на сервер? 🚩
        </h2>
        <div class="HowToPlay__server-address">
          Ввести адрес inmine.ru и<span class="nbsp"></span>порт 19132
        </div>
        <ButtonSecondary @click="open = true">
          Открыть инструкцию
        </ButtonSecondary>
      </div>
    </div>
    <HowToPlayModal :open="open" @close="open = false"/>
  </div>
</template>

<script>
import ButtonSecondary from "@/components/ButtonSecondary";
import HowToPlayModal from "@/modules/modals/HowToPlayModal";



export default {
  name: "HowToPlay",
  components: {HowToPlayModal, ButtonSecondary},
  data: () => ({
    open: false
  }),
}
</script>

<style lang="scss">
.HowToPlay {

  &__content {
    padding: 40px 24px 250px 40px;

    background-image: url("~@/assets/images/bg/how-to-play.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;

    @include mobile {
      min-height: 170px;
      padding: 0 100px 0 0;
      margin-right: -20px;

      background-image: url("~@/assets/images/bg/how-to-play-mobile.png");
      background-position: right;
    }
  }

  &__title {
    margin-bottom: 12px;

    @include mobile {
      margin-bottom: 8px;
    }
  }

  &__server-address {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
    color: $textGray;

    @include mobile {
      margin-bottom: 32px;
      font-size: 14px;
    }
  }
}
</style>