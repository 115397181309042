<template>
  <div
      class="NotificationItem"
      :class="`NotificationItem--type-${notification.type}`"
      @click="remove"
  >
    <div class="NotificationItem__message">
      {{ notification.message }}
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "NotificationItem",
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    remove() {
      this.removeNotification(this.notification.id);
    },
    ...mapMutations('notifications', ['removeNotification']),
  },
}
</script>

<style lang="scss">
.NotificationItem {
  padding: 8px 16px;

  background: $bgLightGray;
  border-radius: 8px;

  text-align: center;
  font-size: 14px;
  font-weight: normal;
  line-height: 130%;
  color: $white;

  cursor: pointer;
  pointer-events: all;

  & + & {
    margin-top: 16px;

    @include mobile {
      margin-top: 12px;
    }
  }

  &--type {

    &-error {
      background: $primary;
    }
  }
}
</style>
