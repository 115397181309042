<template>
  <h1 v-if="server" class="mobile-small">
    Купить донат на сервере #{{ server.id }}<br>
    на<span class="nbsp"></span>ник<span class="nbsp"></span>{{ nickname }}
  </h1>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TitleWithNicknameAndServer",
  computed: {
    ...mapState('purchase', ['nickname', 'server']),
  },
}
</script>
