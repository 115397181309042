<template>
  <ul class="HowToPlayAboutServer">
    <li class="HowToPlayAboutServer__item">
      <p class="HowToPlayAboutServer__label">Адрес сервера</p>
      <p class="HowToPlayAboutServer__value">inmine.ru</p>
    </li>
    <li class="HowToPlayAboutServer__item">
      <p class="HowToPlayAboutServer__label">Порт</p>
      <p class="HowToPlayAboutServer__value">19132</p>
    </li>
    <li class="HowToPlayAboutServer__item">
      <p class="HowToPlayAboutServer__label">Версия игры</p>
      <p class="HowToPlayAboutServer__value">Любая, с 1.12 и выше</p>
    </li>
  </ul>
</template>

<script>
export default {
  name: "HowToPlayAboutServer"
}
</script>

<style lang="scss">
.HowToPlayAboutServer {
  display: flex;
  flex-direction: column;

  &__item {
    font-size: 16px;
    font-weight: 400;

    & + & {
      margin-top: 16px;
    }
  }

  &__label {
    color: $textGray;
    margin-bottom: 2px;
  }

  &__value {
    color: $white;
  }
}
</style>