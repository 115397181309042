<template>
  <div class="SaleCountdown" v-if="discount && discount.discount">
    <p>
      <span class="SaleCountdown__sale-text">{{ discount.message }}</span> закончатся через
    </p>
    <div class="SaleCountdown__date-block">
      <div class="SaleCountdown__time-block">
        <div class="SaleCountdown__time">
          {{ getDays }}
        </div>
        <div class="SaleCountdown__date-unit">
          {{ getDaysDeclension }}
        </div>
      </div>
      <div class="SaleCountdown__colon">:</div>
      <div class="SaleCountdown__time-block">
        <div class="SaleCountdown__time">
          {{ getHours }}
        </div>
        <div class="SaleCountdown__date-unit">
          {{ getHoursDeclension }}
        </div>
      </div>
      <div class="SaleCountdown__colon">:</div>
      <div class="SaleCountdown__time-block">
        <div class="SaleCountdown__time">
          {{ getMinutes }}
        </div>
        <div class="SaleCountdown__date-unit">
          {{ getMinutesDeclension }}
        </div>
      </div>
      <!--      <div class="SaleCountdown__time-block">
              <div class="SaleCountdown__time">
                {{ getSeconds }}
              </div>
              <div class="SaleCountdown__date-unit">
                {{ getSecondsDeclension }}
              </div>
            </div>-->
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {declension} from "@/utils/declension";
import {mapActions, mapState} from "vuex";

export default {
  name: "SaleCountdown",
  data: () => ({
    now: Date.now(),
    interval: null,
  }),
  computed: {
    percent() {
      return this.discount?.percent;
    },
    dateEnd() {
      if (this.discount) return this.discount?.end * 1000;
      else return 0;
    },
    getDays() {
      return this.getTime('days', 1000);
    },
    getDaysDeclension() {
      return declension(this.getDays, 'день', 'дня', 'дней');
    },
    getHours() {
      return this.getTime('hours', 24);
    },
    getHoursDeclension() {
      return declension(this.getHours, 'час', 'часа', 'часов');
    },
    getMinutes() {
      return this.getTime('minutes', 60);
    },
    getMinutesDeclension() {
      return declension(this.getMinutes, 'минута', 'минуты', 'минут');
    },
    getSeconds() {
      return this.getTime('seconds', 60);
    },
    getSecondsDeclension() {
      return declension(this.getSeconds, 'секунда', 'секунды', 'секунд');
    },
    ...mapState('discount', ['discount'])
  },
  methods: {
    getTime(measuring, remainder = 0) {
      return this.addition(moment(this.dateEnd).diff((this.now), measuring) % remainder);
    },
    addition(time) {
      if (time < 0) return '00';
      if (time >= 10) return time;
      else return '0' + time;
    },
    limitCheck() {
      if (this.discount && this.dateEnd - this.now < 0) {
        clearInterval(this.interval);
      }
    },
    ...mapActions('discount', ['getDiscount'])
  },
  created() {
    this.getDiscount();
  },
  mounted() {
    this.limitCheck();
    this.interval = setInterval(() => {
      this.now = Date.now();
      this.limitCheck();
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
}
</script>

<style lang="scss">
.SaleCountdown {
  font-size: 20px;
  font-weight: 400;
  color: $textGray;

  margin-top: 32px;
  border-top: 1px solid rgba($white, 0.04);
  padding-top: 32px;
  margin-bottom: 40px;

  &, &__date-block, &__time-block {
    display: flex;
    align-items: center;
  }

  @include mobile {
    flex-direction: column;
    align-items: stretch;
    font-size: 14px;
    margin-top: 24px;
    padding-top: 12px;
  }


  &__sale-text {
    color: $primary;
  }

  &__date-block {
    margin-left: 16px;
    text-shadow: 0 12px 24px rgba($primary, .4);

    @include mobile {
      margin-left: 0;
      margin-top: 8px;

      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__time-block {
    margin-left: 24px;

    @include mobile {
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__time {
    font-size: 24px;
    font-weight: 900;
    font-style: italic;
    color: $primary;

    @include mobile {
      width: 80px;
      height: 44px;
      margin-bottom: 6px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 22px;
      background: rgba($primary, .08);
      box-shadow: 0 12px 24px rgba($primary, .12);
      border-radius: 4px;
    }
  }

  &__date-unit {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    color: rgba($primary, .48);

    @include mobile {
      margin-left: 0;

      font-size: 12px;
      font-weight: 400;
      color: $darkGray;
    }
  }

  &__colon {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: $darkGray;

    @include PC {
      display: none;
    }
  }
}
</style>
