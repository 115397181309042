export const instructionModal = (shift = 0) => ({
    props: {
        open: Boolean,
    },
    data: () => ({
        activeIndex: 1 + shift,
    }),
    watch: {
        open() {
            this.activeIndex = 1 + shift;
        },
    },
})