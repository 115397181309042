<template>
  <label class="Input__label">
    <input
        class="Input"
        :class="{'Input--large': large}"
        ref="input"
        v-bind="$attrs"
        v-model="inputValue"
    >
    <p class="Input__error-text" v-if="errorText">{{errorText}}</p>
    <CheckmarkIcon
        class="Input__checkmark"
        :class="{'Input__checkmark--show': checkMark}"
    />
  </label>
</template>

<script>

import CheckmarkIcon from '@/assets/images/input-checkmark.svg?inline'

export default {
  name: "Input",
  components: {
    CheckmarkIcon,
  },
  props: {
    value: String,
    large: Boolean,
    checkMark: Boolean,
    errorText: String,
  },
  computed: {
    inputValue: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    }
  }
}
</script>

<style lang="scss">
.Input {
  width: 100%;
  height: 48px;
  padding-left: 16px;
  padding-right: 36px;

  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: none;

  font-size: 18px;
  font-weight: 400;
  color: $white;

  transition: all 0.25s;

  @include mobile {
    font-size: 14px;
  }

  &--large {
    height: 64px;
    padding: 0 24px;
    border-radius: 4px;

    @include mobile {
      height: 48px;
      padding: 0 16px;
    }
  }

  &::placeholder {
    color: $textGray;
  }

  &:focus {
    border-color: rgba(255, 255, 255, 0.24);
  }

  &__label {
    position: relative;
  }

  &__checkmark {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    pointer-events: none;
    opacity: 0;
    transition: opacity 250ms;

    &--show {
      opacity: 1;
    }
  }

  &__error-text {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: $primary;
  }
}
</style>
