<template>
  <button
      class="Button"
      :class="{'Button--large': large}"
      v-bind="$attrs"
      @click="$emit('click')"
  >
    <slot/>
    <span class="Button__arrow" v-if="large">
      <ArrowIcon/>
    </span>
  </button>
</template>

<script>

import ArrowIcon from '@/assets/images/button-arrow.svg?inline'

export default {
  name: "Button",
  components: {
    ArrowIcon
  },
  props: {
    large: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss">
.Button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 20px;

  box-shadow: 0 24px 40px rgba(243, 63, 63, 0.16);
  background-color: $primary;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms;

  &:hover {
    background-color: #D12D2D;
    box-shadow: 0 12px 40px rgba(225, 49, 49, 0.4);
  }

  &:active {
    background-color: #B22424;
    box-shadow: 0 12px 24px rgba(225, 49, 49, 0.24);
  }

  &:disabled {
    background-color: $darkGray;
    color: $bgLightGray;
    box-shadow: none;

    svg path {
      fill: $bgLightGray;
    }
  }

  &__arrow {
    display: flex;
    align-items: center;
    margin-left: 40px;
  }

  &--large {
    height: 64px;
    padding: 0 24px;
    font-weight: 700;
    font-size: 18px;
    box-shadow: 0 24px 40px rgba(243, 63, 63, 0.16);

    @include mobile {
      height: 52px;
      padding: 0 16px;
      font-size: 16px;
    }

    &:hover {
      box-shadow: 0 24px 60px rgba(243, 63, 63, 0.4);
    }

    &:active {
      background-color: #B22424;
      box-shadow: 0 24px 40px rgba(243, 63, 63, 0.16);
    }
  }
}
</style>