<template>
  <div class="PrivilegeCard dark-card" @click="$emit('click')">
    <div class="PrivilegeCard__info">
      <div class="PrivilegeCard__name">
        {{ name }}
      </div>
      <div class="PrivilegeCard__price">
        {{ price | price }}
        <span v-if="startingPrice && startingPrice !== price" class="PrivilegeCard__starting-price">
          {{ startingPrice | price }}
        </span>
      </div>
    </div>
    <ButtonSecondary>
      Купить
    </ButtonSecondary>
  </div>
</template>

<script>
import ButtonSecondary from "@/components/ButtonSecondary";

export default {
  name: "PrivilegeCard",
  components: {ButtonSecondary},
  props: {
    name: String,
    price: Number,
    startingPrice: Number,
  }
}
</script>

<style lang="scss">
.PrivilegeCard {
  cursor: pointer;

  @include mobile {
    padding: 12px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $bgLightGray;

    button {
      height: 38px;
    }
  }

  .ButtonSecondary {
    @include PC {
      width: 100%;
    }
  }

  &__info {
    margin-bottom: 24px;

    @include mobile {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }

  &__name {
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 600;
    color: $white;

    @include mobile {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &__price {
    font-size: 16px;
    font-weight: 700;
    color: $primary;

    @include mobile {
      font-size: 14px;
    }
  }

  &__starting-price {
    margin-left: 8px;
    font-weight: 400;
    color: $textGray;
    text-decoration: line-through;

    @include mobile {
      margin-left: 4px;
    }
  }
}
</style>
