<template>
  <div class="Footer">
    <div class="Footer__row">
      <LogoIcon/>
      <div class="Footer__agreement hide-mobile">
        <FooterAgreement/>
      </div>
      <a class="Footer__link link" href="https://phpmc.ru" target="_blank">
        сделано в phpmc
      </a>
    </div>
    <div class="Footer__line hide-pc"/>
    <div class="Footer__agreement hide-pc">
      <FooterAgreement/>
    </div>
    <div class="Footer__line"/>
    <div class="Footer__row Footer__text-block">
      <div class="Footer__text">
        <span class="Footer__text-prefix">ИП</span>
        Асафьев Дмитрий Денисович
      </div>
      <div class="Footer__text">
        <span class="Footer__text-prefix">ИНН</span>
        780257559584
      </div>
      <div class="Footer__text">
        <span class="Footer__text-prefix">ОГРН</span>
        319784700040575
      </div>
    </div>
  </div>
</template>

<script>
import LogoIcon from "@/modules/app/LogoIcon";
import FooterAgreement from "@/modules/app/FooterAgreement";

export default {
  name: "Footer",
  components: {FooterAgreement, LogoIcon}
}
</script>

<style lang="scss">
.Footer {
  margin-top: 60px;
  padding-bottom: 40px;

  @include mobile {
    margin-top: 32px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__line {
    height: 1px;
    margin: 20px 0;

    background-color: rgba($white, .04);

    @include mobile {
      margin: 16px 0;
    }
  }

  &__agreement {
    margin: 0 20px;

    @include mobile {
      margin: 0;
    }
  }

  &__text-block {
    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    color: $textGray;

    @include mobile {
      display: grid;
      grid-template-columns: 40px 1fr;
      grid-column-gap: 20px;

      & + & {
        margin-top: 6px;
      }
    }
  }

  &__text-prefix {
    margin-right: 8px;
  }
}
</style>