<template>
  <div class="StepModalCount">
    {{ number }} шаг
    <div class="StepModalCount__indicator hide-pc">
      <div
          class="StepModalCount__item"
          :class="{'StepModalCount__item--active': index <= number}"
          v-for="index of steps"
          :key="index"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "StepModalCount",
  props: {
    number: Number,
    steps: Number,
  }
}
</script>

<style lang="scss">
.StepModalCount {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $textGray;

  display: flex;
  align-items: center;

  @include mobile {
    color: $white;
  }

  &__indicator {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }

  &__item {
    width: 5px;
    height: 5px;

    background: rgba($white, 0.12);
    transform: rotate(45deg);

    &--active {
      background: rgba($white, 0.48);
    }

    & + & {
      margin-left: 7px;
    }
  }
}
</style>