<template>
  <div class="dark-block">
    <div class="PlayersReviews">
      <h2 class="PlayersReviews__title">
        Честный сервер —<br>
        проверено игроками ✨
      </h2>
      <div class="PlayersReviews__body">
        <PlayersReviewsSlider :list="reviews"/>
      </div>
      <div class="PlayersReviews__write-review-block">
          <div class="PlayersReviews__write-review-block-info">
            <div class="PlayersReviews__write-review-block-title">
              Уже играешь с нами?
            </div>
            <div class="PlayersReviews__write-review-block-text">
              Помоги рассказать другим игрокам о сервере — оставь отзыв в нашей группе ВКонтакте
            </div>
          </div>
          <a href="https://vk.com/inmine" target="_blank" class="PlayersReviews__write-review-block-link">
            <ButtonSecondary>
              Оставить отзыв в группе
            </ButtonSecondary>
          </a>
        </div>
    </div>
  </div>
</template>

<script>
import PlayersReviewsSlider from "@/components/PlayersReviewsSlider";
import ButtonSecondary from "@/components/ButtonSecondary";
import {mapActions, mapState} from "vuex";

export default {
  name: "PlayersReviews",
  components: {ButtonSecondary, PlayersReviewsSlider},
  computed: {
    ...mapState('accounts', ['reviews']),
  },
  methods: {
    ...mapActions('accounts', ['getReviews'])
  },
  created() {
    if (!this.reviews?.length) {
      this.getReviews();
    }
  }
}
</script>

<style lang="scss">
.PlayersReviews {
  padding: 60px;
  overflow: hidden;

  @include mobile {
    margin-left: -20px;
  }

  @include mobile {
    padding: 0;
  }

  &__title {
    margin-bottom: 32px;

    @include mobile {
      padding-left: 20px;
      margin-bottom: 20px;
    }
  }

  &__body {
    margin-bottom: 32px;

    @include mobile {
      padding: 0 20px;
    }
  }

  &__write-review-block {
    padding: 24px;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    background: $bgLightGray;
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
    border-radius: 8px;

    @include mobile {
      position: relative;
      max-width: 290px;
      //margin-left: -20px;
      padding: 20px;

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      flex-direction: column;
      align-items: flex-start;

      background: transparent;

      & > * {
        position: relative;
        z-index: 2;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $bgLightGray;
        transform: skewX(-2.5deg);
      }
    }

    &-info {
      padding-right: 80px;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      line-height: 20px;

      @include mobile {
        margin-bottom: 16px;
        padding-right: 0;
      }
    }

    &-title {
      margin-bottom: 4px;
      font-weight: 700;
      color: $white;

      @include mobile {
        margin-bottom: 6px;
      }
    }

    &-text {
      font-weight: 400;
      color: $textGray;
    }

    &-link {
      flex-shrink: 0;
    }
  }
}
</style>