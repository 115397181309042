import Vue from 'vue'
import App from './App.vue'
import '@/assets/style/index.scss'
import router from "@/router";
import store from "@/store";
import {initAxios} from "@/utils/init";
import moment from 'moment';

moment.locale('ru');

Vue.config.productionTip = false

initAxios('https://api.inmine.ru/');

Vue.filter('price', function (val) {
    const v = Math.round(val);
    return `${v?.toLocaleString() ?? v}\xa0₽`;
});
Vue.filter('date', function (date) {
    return moment(date).calendar();
});

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
