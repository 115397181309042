<template>
  <div class="dark-block">
    <div class="LastPurchasesPlayers">
      <h2 class="LastPurchasesPlayers__title">
        Последние<br class="hide-mobile">
        покупки ⏳
      </h2>
      <div class="LastPurchasesPlayers__list">
        <div class="LastPurchasesPlayers__item" v-for="({username, server}, i) in donaters" :key="username+i">
          <img class="LastPurchasesPlayers__face-img" src="@/assets/images/game-face.png" alt="face">
          <div class="LastPurchasesPlayers__text">
            <span class="LastPurchasesPlayers__nickname">
            {{ username }}
            </span>
            купил донат <span class="LastPurchasesPlayers__text-nowrap">на сервере #{{ server }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "LastPurchasesPlayers",
  computed: {
    ...mapState('privileges', ['donaters']),
  },
  methods: {
    ...mapActions('privileges', ['getDonaters'])
  },
  created() {
    if (!this.donaters?.length) {
      this.getDonaters();
    }
  }
}
</script>

<style lang="scss">
.LastPurchasesPlayers {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 40px 25px;
  position: relative;

  @include mobile {
    padding: 0 0 20px;
  }

  &::after {
    content: '';
    height: 120px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, #0A0A0A 100%);
  }

  &__title {
    margin-bottom: 24px;

    @include mobile {
      margin-bottom: 20px;
    }
  }

  &__list {
    border-bottom: solid 1px rgba($white, .04);
  }

  &__item {
    padding: 16px 0;
    display: flex;
    align-items: center;
    border-top: solid 1px rgba($white, .04);

    @include mobile {
      padding: 12px 0;
    }
  }

  &__face-img {
    height: 42px;
    width: 42px;
    margin-right: 20px;

    border-radius: 2px;
    object-fit: contain;

    @include mobile {
      height: 36px;
      width: 36px;
      margin-right: 16px;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 600;
    color: $textGray;

    @include mobile {
      font-size: 14px;
      line-height: 130%;
    }
  }

  &__nickname {
    color: $white;
    margin-right: 4px;
  }

  &__text-nowrap {
    white-space: nowrap;
  }
}
</style>