<template>
  <div class="NotificationsManagerHolder">
    <div class="NotificationsManagerHolder__list">
      <TransitionGroup name="NotificationsManagerHolder__list--animation">
        <NotificationItem
            v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
        />
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import NotificationItem from "./NotificationItem";

export default {
  name: "NotificationsManagerHolder",
  components: {NotificationItem},
  computed: {
    ...mapState('notifications', ['notifications'])
  }
}
</script>

<style lang="scss">
.NotificationsManagerHolder {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  pointer-events: none;
  z-index: 10;

  &__list {
    max-width: 600px;
    margin: 0 auto;
    padding: 24px;
    box-sizing: content-box;

    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      padding: 12px 16px;
    }

    &--animation {
      &-enter-active, &-leave-active {
        transition: all 250ms;
      }
      &-enter, &-leave-to {
        opacity: 0;
        transform: translateY(24px);
      }
    }
  }
}
</style>
