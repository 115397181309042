import Vapi from "vuex-rest-api";
import Vue from "vue";

export default new Vapi({
    axios: Vue.axios,
    state: {
        rules: {
            main: {
                title: '',
                list: []
            }
        },
        titles: [],
        update: ''
    }
})
    .get({
        action: 'getRules',
        property: 'rules',
        path: '/etc/rules/',
        onSuccess(s, {data}) {
            s.rules = data;
            s.titles = data.rules.map(({title}) => title);
        }
    })
    .getStore({namespaced: true});