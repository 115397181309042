import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

let id = 0;
const createId = () => ++id;

export const NOTIFICATION_ERROR_TYPE = 'error';

export default {
    namespaced: true,
    state: {
        notifications: [],
    },
    actions: {
        pushNotification({commit}, notification) {
            const id = createId();
            notification = {
                ...notification,
                id,
            }
            commit('addNotification', notification);
            setTimeout(() => {
                commit('removeNotification', id);
            }, 5000);
            return id;
        },
        pushError({dispatch}, message) {
            return dispatch('pushNotification', {
                type: NOTIFICATION_ERROR_TYPE,
                message,
            });
        },
    },
    mutations: {
        addNotification(s, notification) {
            s.notifications.push(notification);
        },
        removeNotification(s, id) {
            const notificationIndex = s.notifications.findIndex((notification) => notification.id === id);
            if (~notificationIndex) {
                s.notifications.splice(notificationIndex, 1);
            }
        },
    },
}
