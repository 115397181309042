<template>
  <div class="dark-block">
    <div class="ServersPlayersOnline">
      <h2 class="ServersPlayersOnline__title">
        <span class="ServersPlayersOnline__title-online">
          {{ onlineData.all }} игроков онлайн
        </span>
        <br>
        на всех серверах
      </h2>
      <div class="ServersPlayersOnline__list">
        <div
            class="ServersPlayersOnline__item"
            v-for="({port, online, maxOnline}, index) in onlineData.list"
            :key="index"
        >
          <div class="ServersPlayersOnline__indicator">
            <ServerIndicator :indicator-value="(maxOnline - online) / maxOnline" show-indicator>
              {{ index }}
            </ServerIndicator>
          </div>
          <div class="ServersPlayersOnline__info-block">
            <div class="ServersPlayersOnline__online">
              <span class="ServersPlayersOnline__online-value">{{ online }} из {{ maxOnline }}</span>
              игроков онлайн
            </div>
            <div class="ServersPlayersOnline__actions">
              <div class="ServersPlayersOnline__port">
                Порт <span class="ServersPlayersOnline__port-value">{{ port }}</span>
              </div>
              <AddServer :port="port">
                <ButtonOutline>
                  Добавить в игре
                </ButtonOutline>
              </AddServer>
            </div>
          </div>
        </div>
        <div class="ServersPlayersOnline__item">
          <div class="ServersPlayersOnline__news-indicator"/>
          <div class="ServersPlayersOnline__online">
            Новости об обновлениях в<span class="nbsp"></span>нашей
            <a href="https://vk.com/inmine" target="_blank" class="link link--primary ServersPlayersOnline__link">группе
              ВКонтакте</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServerIndicator from "@/components/ServerIndicator";
import ButtonOutline from "@/components/ButtonOutline";
import {mapActions, mapState} from "vuex";
import AddServer from "@/components/AddServer";

export default {
  name: "ServersPlayersOnline",
  components: {AddServer, ButtonOutline, ServerIndicator},
  computed: {
    onlineData() {
      if (this.online) {
        const {all, ...list} = this.online;
        return {all, list};
      } else return {};
    },
    ...mapState('servers', ['online']),
  },
  methods: {
    ...mapActions('servers', ['getOnline'])
  },
  created() {
    if (!this.online) {
      this.getOnline();
    }
  }
}
</script>

<style lang="scss">
.ServersPlayersOnline {
  padding: 40px;

  @include mobile {
    padding: 0;
  }

  &__title {
    margin-bottom: 40px;
    color: $textGray;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  &__title-online {
    color: $primary;
    text-shadow: 0 16px 32px rgba(225, 49, 49, 0.32);
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include mobile {
      display: flex;
      flex-direction: column;
      border-top: solid 1px rgba($white, .04);
    }
  }

  &__item {
    padding: 24px 0;

    @include mobile {
      display: flex;
      align-items: center;
      padding: 16px 0;
      border-bottom: solid 1px rgba($white, .04);
    }

    @include PC {
      &:nth-child(2n + 1) {
        border-right: 1px solid rgba($white, .04);
        padding-right: 30px;
      }

      &:nth-child(2n) {
        padding-left: 30px;
      }

      &:not(:last-child) {
        &:not(:nth-last-child(2)) {
          border-bottom: 1px solid rgba($white, .04);
        }

        &:nth-last-child(2) {
          &:nth-child(2n) {
            border-bottom: 1px solid rgba($white, .04);
          }
        }
      }
    }
  }

  &__info-block {
    display: flex;
    flex-direction: column;

    @include mobile {
      margin-left: 20px;
    }
  }

  &__indicator {
    @include PC {
      margin-bottom: 16px;
    }
  }

  &__link {
    font-size: 14px;
  }

  &__online {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: $textGray;
  }

  &__online-value {
    color: $primary;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__port {
    height: 21px;
    padding: 0 6px;
    margin-right: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 2px;

    border: solid 1px rgba($white, .04);
    color: $textGray;
    font-weight: 500;
    font-size: 12px;
  }

  &__port-value {
    margin-left: 3px;
    color: $white;
  }

  &__news-indicator {
    height: 44px;
    width: 44px;
    flex-shrink: 0;
    margin-bottom: 16px;
    transform: skewX(-5deg);
    border-radius: 2px;
    background-color: $bgLightGray;
    background-image: url("~@/assets/images/news-indecator.png");
    background-position: center;
    background-repeat: no-repeat;

    @include mobile {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
}
</style>