<template>
  <div class="PaymentResultModule">
    <div class="PaymentResultModule__icon">
      <slot name="icon"/>
    </div>
    <h1 v-if="title" class="PaymentResultModule__title" v-html="title"/>
    <div v-if="subtitle" class="PaymentResultModule__subtitle"
         :class="{'PaymentResultModule__subtitle--success': !error, 'small': isSmall}">
      {{ subtitle }}<br>
      {{ secondtitle }}
    </div>
    <div class="PaymentResultModule__body">
      <slot name="body"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentResultModule",
  props: {
    title: String,
    subtitle: String,
    secondtitle: String,
    error: Boolean,
  },
  computed: {
    isSmall() {
      return this.subtitle?.length < 10;
    }
  }
}
</script>

<style lang="scss">
.PaymentResultModule {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    margin-bottom: 32px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  &__title {
    margin-bottom: 8px;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 130%;
    color: $textLightGray;

    &.small {
      letter-spacing: 0.32em;
    }

    &--success {
      font-size: 14px;
      font-weight: 600;
      color: $secondary;
      text-transform: uppercase;
    }
  }

  &__body {
    width: 100%;
    padding-top: 40px;

    @include mobile {
      padding-top: 64px;
    }
  }
}
</style>