<template>
  <div class="PlayersReviewsSlider">
    <div class="PlayersReviewsSlider__slide">
      <div class="PlayersReviewsSlider__player">
        <img class="PlayersReviewsSlider__face" :src="activeReview.image"/>
        <div class="PlayersReviewsSlider__info">
          <div class="PlayersReviewsSlider__name">
            {{ activeReview.fio }}
          </div>
          <div class="PlayersReviewsSlider__about-server">
            на сервере {{ activeReview.login }}
          </div>
        </div>
      </div>
      <div class="PlayersReviewsSlider__review-text">
        {{ activeReview.text }}
      </div>
      <div class="PlayersReviewsSlider__next-slide-block">
        <div class="PlayersReviewsSlider__next-slide hide-mobile" @click="nextSlide">
          <ArrowIcon/>
        </div>
        <ButtonOutline class="hide-pc" large arrow @click="nextSlide">
          Следующий отзыв
        </ButtonOutline>
        <div class="PlayersReviewsSlider__slide-counter">
          {{ activeSlide + 1 }} / {{ list.length }}
        </div>
      </div>
    </div>
    <div class="PlayersReviewsSlider__slide PlayersReviewsSlider__slide--next hide-mobile">
      <div class="PlayersReviewsSlider__player">
        <img class="PlayersReviewsSlider__face" src="@/assets/images/game-face.png"/>
        <div class="PlayersReviewsSlider__info">
          <div class="PlayersReviewsSlider__name">
            {{ activeReview.fio }}
          </div>
          <div class="PlayersReviewsSlider__about-server">
            на сервере {{ activeReview.login }}
          </div>
        </div>
      </div>
      <div class="PlayersReviewsSlider__review-text">
        {{ activeReview.text }}
      </div>
      <div class="PlayersReviewsSlider__next-slide-block">
        <div class="PlayersReviewsSlider__next-slide" @click="nextSlide">
          <ArrowIcon/>
        </div>
        <div class="PlayersReviewsSlider__slide-counter">
          {{ activeSlide + 1 }} / {{ list.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ArrowIcon from '@/assets/images/slider-arrow.svg?inline'
import ButtonOutline from "@/components/ButtonOutline";

export default {
  name: "PlayersReviewsSlider",
  components: {
    ButtonOutline,
    ArrowIcon,
  },
  props: {
    list: Array,
  },
  data: () => ({
    activeSlide: 0,
  }),
  computed: {
    nextSlideIndex() {
      return this.activeSlide + 1 < this.list.length
          ? this.activeSlide + 1
          : 0;
    },
    activeReview() {
      return this.list[this.activeSlide] ?? [];
    },
    nextActiveReview() {
      return this.list[this.nextSlideIndex];
    },
  },
  methods: {
    nextSlide() {
      this.activeSlide = this.nextSlideIndex;
    },
  }
}
</script>

<style lang="scss">
.PlayersReviewsSlider {
  padding-right: 260px;
  position: relative;

  @include mobile {
    padding-right: 0;
  }

  &__slide {

    &--next {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: calc(100% - 165px);
      width: 500px;
      opacity: 0.16;
      filter: blur(6px);
    }
  }

  &__player {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    @include mobile {
      margin-bottom: 16px;
    }
  }

  &__face {
    height: 48px;
    width: 48px;
    border-radius: 2px;

    @include mobile {
      height: 40px;
      width: 40px;
    }
  }

  &__info {
    margin-left: 22px;
    display: flex;
    flex-direction: column;

    @include mobile {
      margin-left: 16px;
    }
  }

  &__name {
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 600;
    color: $white;

    @include mobile {
      font-size: 14px;
    }
  }

  &__about-server {
    font-size: 16px;
    font-weight: 400;
    color: $textGray;

    @include mobile {
      font-size: 14px;
    }
  }

  &__review-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: $white;

    @include mobile {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
    }
  }

  &__next-slide {
    height: 48px;
    width: 48px;

    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-100%);

    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    background: rgba($white, .04);
    box-shadow: 0 12px 24px rgba($white, .04);
    border-radius: 4px;
    transition: box-shadow 250ms;

    @include mobile {
      position: static;
      transform: none;
    }

    &:hover {
      box-shadow: 0 16px 32px rgba($white, .06);
    }
  }

  &__slide-counter {
    position: absolute;
    right: 0;
    top: -100px;

    flex-shrink: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: $textGray;

    @include mobile {
      min-width: 40px;
      text-align: right;
      margin-left: 20px;
      position: static;
    }
  }

  &__next-slide-block {
    @include mobile {
      padding-top: 20px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        width: 100%;
      }
    }
  }
}
</style>