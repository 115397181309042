<template>
  <div
      class="ServerCard dark-card"
      :class="{'ServerCard--fire': isLast}"
      @click="selectServer(server)"
  >
    <div class="ServerCard__main">
      <ServerIndicator large>
        {{ server.id }}
      </ServerIndicator>
      <div class="ServerCard__info">
        <div class="ServerCard__port-row">
          <div class="ServerCard__port">
            Порт {{ server.port }}
          </div>
          <FireIcon v-if="isLast"/>
        </div>
        <div class="ServerCard__role-block">
          <RoleStarIcon/>
          <span class="ServerCard__role">{{ server.donate || defaultDonate || 'Игрок' }}</span>
        </div>
      </div>
    </div>
    <div class="hide-pc ServerCard__button">
      <ButtonOutline large>
        <ArrowIcon/>
      </ButtonOutline>
    </div>
  </div>
</template>

<script>
import ServerIndicator from "@/components/ServerIndicator";
import RoleStarIcon from '@/assets/images/role-star.svg?inline'
import FireIcon from '@/assets/images/server-fire.svg?inline'
import ArrowIcon from '@/assets/images/slider-arrow.svg?inline'
import {mapMutations, mapState} from "vuex";
import ButtonOutline from "@/components/ButtonOutline";

export default {
  name: "ServerCard",
  components: {ButtonOutline, ServerIndicator, RoleStarIcon, FireIcon, ArrowIcon},
  props: {
    server: Object,
    isLast: Boolean
  },
  computed: {
    donate() {
      return this.privileges.find(({permission}) => permission === this.server.donate)?.name;
    },
    defaultDonate() {
      return this.privileges.find(({permission}) => permission === this.settings?.default)?.name;
    },
    privileges() {
      return this.server?.products ?? [];
    },
    ...mapState('privileges', ['settings']),
  },
  methods: {
    selectServer(server) {
      this.setServer(server);
      if (server.is_ban) {
        this.$router.push({name: 'paymentBanned'});
      } else {
        this.$router.push({name: 'privileges'});
      }
    },
    ...mapMutations('purchase', ['setServer']),
  }
}
</script>

<style lang="scss">
.ServerCard {
  display: flex;
  align-items: center;
  cursor: pointer;

  @include mobile {
    padding: 16px 0;
    border-top: 1px solid rgba($white, .04);

    justify-content: space-between;

    &, &:hover {
      background: none;
      box-shadow: none;
    }
  }

  &--fire {

  }

  &__main {
    display: flex;
    align-items: center;
  }

  &__port-row {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }

  &__port {
    margin-right: 4px;

    font-size: 18px;
    font-weight: 600;

    @include mobile {
      font-size: 14px;
    }
  }

  &__info {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: self-start;
  }

  &__role-block {
    display: flex;
    align-items: center;
  }

  &__role {
    margin-left: 4px;

    font-size: 16px;
    font-weight: 400;
    color: $textGray;

    @include mobile {
      font-size: 14px;
    }
  }

  &__button button {
    height: 44px;
    width: 44px;
    padding: 0;

    justify-content: center;
  }
}
</style>
