<template>
  <div class="FooterAgreement">
    <a class="FooterAgreement__link link" target="_blank" href="https://docs.inmine.ru/privacy.pdf">
      Политика конфиденциальности
    </a>
    <a class="FooterAgreement__link link" target="_blank" href="https://docs.inmine.ru/agreement.pdf">
      Пользовательское соглашение
    </a>
  </div>
</template>

<script>
export default {
  name: "FooterAgreement"
}
</script>

<style lang="scss">
.FooterAgreement {
  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__link  {
    & + & {
      margin-left: 32px;

      @include mobile {
        margin-left: 0;
        margin-top: 14px;
      }
    }
  }
}
</style>