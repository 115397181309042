const className = 'scroll-blocked';
const bodyClassList = document.body.classList;

export default (stateName = 'open') => ({
    watch: {
        [stateName](value) {
            if (value) bodyClassList.add(className);
            else bodyClassList.remove(className);
        }
    }
});
