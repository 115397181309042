<template>
  <div class="HowToDoModal" @click.self="onClose" v-if="open">
    <div class="HowToDoModal__content dark-block">
      <h3 class="HowToDoModal__title hide-mobile">
        {{title}}
      </h3>
      <div class="HowToDoModal__close mobile-button"  @click="onClose">
        <CloseIcon class="hide-mobile"/>
        <CloseIconMobile class="hide-pc"/>
      </div>
      <slot/>
    </div>
  </div>
</template>

<script>

import CloseIcon from '@/assets/images/modal-close.svg?inline'
import CloseIconMobile from '@/assets/images/close-modal-mobile.svg?inline'
import scrollBlockBinder from "@/mixins/scrollBlockBinder";

export default {
  name: "HowToDoModal",
  components: {
    CloseIcon,
    CloseIconMobile,
  },
  mixins: [scrollBlockBinder()],
  props: {
    open: Boolean,
    title: String,
  },
  methods: {
    onClose() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss">
.HowToDoModal {
  z-index: 101;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 80px 0;
  overflow-y: auto;

  background: rgba(10, 10, 10, .48);
  backdrop-filter: blur(8px);

  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include mobile {
    padding: 0;
    border-radius: 0;
  }

  &__content {
    width: 784px;
    padding: 40px 48px 64px;
    position: relative;
    overflow: auto;

    @include mobile {
      width: 100%;
      height: 100%;
      padding: 0 20px 20px;
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;

    path {
      transition: 250ms;
    }

    &:hover {
      path {
        stroke: $textLightGray;
      }
    }

    @include mobile {
      z-index: 5555;

      path {
        stroke: $white;
      }
    }
  }

  &__title {
    margin-bottom: 32px;
    padding-right: 16px;

    font-weight: 700;
    font-size: 36px;
    line-height: 130%;
    color: $white;
  }
}
</style>