import Vue from 'vue';
import Vapi from "vuex-rest-api"

const store = new Vapi({
    axios: Vue.axios,
    state: {}
})
    .get({
        action: 'getOnline',
        property: 'online',
        path: '/online/',
        onSuccess(s, {data}) {
            s.online = data;
        }
    })
    .getStore({namespaced: true});

export default {
    ...store,

    mutations: {
        ...store.mutations,
        clear(s) {
            s.servers = []
        }
    },
}
