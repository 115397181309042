<template>
  <MainPageLayout>
    <div class="HomePage">
      <h1>
        Введите никнейм, <br>
        чтобы выбрать привилегию
      </h1>
      <form class="HomePage__nickname-row" @submit.prevent="nicknameSelectHandle">
        <Input
         v-model="nicknameInputValue"
         placeholder="Никнейм"
         :error-text="errorText"
         large
         ref="input"
        />
        <div class="HomePage__select-button">
          <Button type="submit" large>
            Выбрать привилегию
          </Button>
        </div>
        <div class="HomePage__possible-nickname-row">
          <PossibleNicknameList :list="accounts" @select="goToNick" />
        </div>
      </form>
      <SaleCountdown />
    </div>
  </MainPageLayout>
</template>

<script>
import MainPageLayout from "@/layouts/MainPageLayout";
import Input from "@/components/Input";
import Button from "@/components/Button";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import SaleCountdown from "@/components/SaleCountdown";
import PossibleNicknameList from "@/components/PossibleNicknameList";
import { addNickStorage } from '@/utils/localStorage';

export default {
  name: "HomePage",
  components: { PossibleNicknameList, SaleCountdown, Button, Input, MainPageLayout },
  data: () => ({
    errorText: '',
  }),
  computed: {
    nicknameInputValue: {
      set(value) {
        this.clear();
        this.setNickname(value);
      },
      get() {
        return this.nickname;
      },
    },
    ...mapState('accounts', ['accounts']),
    ...mapState('purchase', ['nickname']),
    ...mapGetters('purchase', ['nicknameIsValid']),
  },
  methods: {
    nicknameSelectHandle() {
      if (this.nicknameIsValid) {
        this.getPrivileges({
          params: {
            nick: this.nickname,
          },
        }).then(() => {
          addNickStorage(this.nickname);
          this.$router.push({ name: 'servers' });
        }, (err) => {
          this.setNickname('');
          this.pushError(err);
          this.$refs.input.$refs.input.focus();
        });
      } else {
        this.errorText = 'Укажите никнейм';
      }
    },
    goToNick(nick) {
      this.clear();
      this.setNickname(nick);
      this.nicknameSelectHandle();
    },
    ...mapMutations('purchase', ['setNickname']),
    ...mapActions('notifications', ['pushError']),
    ...mapMutations('servers', ['clear']),
    ...mapActions('privileges', ['getPrivileges']),
  },
  created() {
    /*    if (!this.accounts?.length) {
          this.getAccounts();
        }*/
  },
  watch: {
    nicknameInputValue() {
      this.errorText = '';
    },
  },
}
</script>

<style lang="scss">
.HomePage {

  &__nickname-row {
    max-width: 785px;
    margin-bottom: 12px;
    display: grid;
    width: auto;
    grid-template-columns: 1fr auto;
    grid-gap: 12px 16px;

    @include mobile {
      grid-gap: 0;
      display: flex;
      flex-direction: column;
    }
  }

  &__possible-nickname-row {
    grid-column: 1 / 3;

    @include mobile {
      margin-top: 10px;
      margin-bottom: 16px;
    }
  }

  &__select-button {
    button {
      width: 100%;
    }

    @include mobile {
      order: 3;
    }
  }
}
</style>
