<template>
  <div class="PaymentSuccessModule">
    <PaymentResultModule
        :title="title"
        :subtitle="subtitle"
    >
      <template #icon>
        <div class="PaymentSuccessModule__icon-block">
          <CheckmarkIcon/>
        </div>
      </template>
      <template #body>
        <PaymentCheck>
          <img class="hide-mobile" src="@/assets/images/camera.png" alt="camera.png">
          <div class="PaymentSuccessModule__info-block">
            <div class="PaymentSuccessModule__info-label">
              Сделайте скриншот страницы.
            </div>
            <div class="PaymentSuccessModule__info-text">
              Он понадобится, если что-то пойдет не<span class="nbsp"></span>так и вы не получите привилегию.
            </div>
          </div>
          <RouterLink class="hide-pc" :to="{name: 'home'}">
            <ButtonSecondary>
              Вернуться на главную
            </ButtonSecondary>
          </RouterLink>
        </PaymentCheck>
      </template>
    </PaymentResultModule>
  </div>
</template>

<script>
import PaymentResultModule from "@/modules/payment/PaymentResultModule";
import CheckmarkIcon from '@/assets/images/success-payment-checkmark.svg?inline'
import PaymentCheck from "@/modules/payment/PaymentCheck";
import ButtonSecondary from "@/components/ButtonSecondary";

export default {
  name: "PaymentSuccessModule",
  components: {ButtonSecondary, PaymentCheck, PaymentResultModule, CheckmarkIcon},
  props: {
    title: String,
    subtitle: String,
  }
}
</script>

<style lang="scss">
.PaymentSuccessModule {

  &__info-block {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;

    @include mobile {
      font-size: 14px;
      margin-bottom: 24px;
    }
  }

  &__info-label {
    color: $white;
    margin-bottom: 2px;
  }

  &__info-text {
    color: $textLightGray;
  }

  &__icon-block {
    height: 80px;
    width: 80px;

    display: flex;
    justify-content: center;
    align-items: center;

    transform: skewX(-5deg);
    background: linear-gradient(180deg, #3DB960 0%, #3AA558 100%);
    border-radius: 4px;
    box-shadow: 0 32px 80px rgba(61, 185, 96, 0.32),
    0 18px 25px rgba(61, 185, 96, 0.2),
    0 9px 10px rgba(61, 185, 96, 0.1);
  }
}
</style>