<template>
  <div class="Privilege" :class="{'Privilege--open': open}">
    <div class="Privilege__header" @click="open = !open">
      <div class="Privilege__name-info">
        <div class="Privilege__name">{{ name }}</div>
        <div class="Privilege__price">{{ price | price }}</div>
      </div>
      <div class="Privilege__arrow hide-pc">
        <ArrowIcon/>
      </div>
    </div>
    <div
        class="Privilege__body"
        :class="{'hide-mobile': !open}"
    >
      <ul class="Privilege__list">
        <li class="Privilege__item Privilege__item--command" v-for="(info, cmd) in description.commands" :key="cmd">
            <span class="Privilege__command">
              {{ cmd }}
            </span>
          — {{ info }}
        </li>
        <li class="Privilege__item" v-for="(info) in description.info" :key="info">
          {{ info }}
        </li>
        <li class="Privilege__item" v-if="description.scope">
          Возможности других статусов:
          <span class="Privilege__role">
              {{ description.scope.join(', ') }}
            </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/assets/images/privilege-arrow.svg?inline'

export default {
  name: "Privilege",
  props: {
    name: String,
    price: Number,
    description: Object,
  },
  components: {
    ArrowIcon,
  },
  data: () => ({
    open: false,
  }),
}
</script>

<style lang="scss">
.Privilege {
  padding-bottom: 25px;
  padding-top: 48px;

  display: grid;
  grid-template-columns: 40% 1fr;
  grid-gap: 0 10px;
  justify-content: space-between;

  border-bottom: 1px solid rgba($white, .04);

  @include mobile {
    padding: 0 16px;
    grid-template-columns: 1fr;

    background-color: $bgGray;
    border-bottom: none;
    border-radius: 4px;
    transition: background-color 250ms;

    &--open {
      background-color: $bgLightGray;

      .Privilege__arrow {
        transform: rotate(-180deg);
      }
    }
  }

  &__header {
    @include mobile {
      min-height: 44px;
      padding: 0;
      cursor: pointer;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__body {
    @include mobile {
      padding: 12px 0 16px;
      border-top: 1px solid rgba($white, .04);
    }
  }

  &__name-info {
    display: flex;
    align-items: center;

    @include PC {
      flex-wrap: wrap;
    }
  }

  &__name {
    margin-right: 12px;
    font-size: 28px;
    font-weight: 700;
    color: $white;

    @include mobile {
      margin-right: 8px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__price {
    font-size: 28px;
    font-weight: 400;
    color: $primary;

    @include mobile {
      font-size: 14px;
    }
  }

  &__list {
    padding-left: 16px;
  }

  &__item {
    position: relative;
    font-weight: 600;
    font-size: 16px;
    color: $textLightGray;

    @include mobile {
      font-size: 12px;
    }

    & + & {
      margin-top: 12px;
    }

    &::before {
      position: absolute;
      top: 8px;
      left: -16px;
      content: '';
      height: 6px;
      width: 6px;
      transform: rotate(45deg);
      background-color: $line
    }

    &--command {
      font-weight: 400;

      &::before {
        background-color: rgba($primary, .16);
      }
    }
  }

  &__role {
    display: block;
    max-width: 400px;
  }

  &__command {
    font-weight: 600;
    color: $primary;
  }

  &__arrow {
    display: flex;
    transition: transform 250ms;
  }
}
</style>