<template>
  <div class="PossibleNicknameList" v-if="possibleNicknames.length || list.length">
    <p class="PossibleNicknameList__text">Возможно,</p>
    <div class="PossibleNicknameList__item" v-for="(nickname, index) in listNicks" :key="index">
      <ButtonSecondary type="button" small @click="$emit('select', nickname)">
        {{ nickname }}
      </ButtonSecondary>
    </div>
  </div>
</template>

<script>
import ButtonSecondary from "@/components/ButtonSecondary";
import {mapState} from "vuex";
import {getNickStorage} from "@/utils/localStorage";

export default {
  name: "PossibleNicknameList",
  components: {ButtonSecondary},
  props: {
    list: Array
  },
  data: () => ({
    possibleNicknames: getNickStorage().reverse(),
  }),
  computed: {
    listNicks() {
      return [...this.possibleNicknames, ...this.list].reduce((tmp, nick) => ((tmp[nick] = nick), tmp), {});
    },
    ...mapState('purchase', ['nickname']),
  },
}
</script>

<style lang="scss">
.PossibleNicknameList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  margin-bottom: -5px;
  padding: 0 20px;

  @include mobile() {
    overflow: auto;
    flex-wrap: nowrap;
    padding: 0 20px - 6px;
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: $textGray;

    @include mobile {
      display: none;
    }
  }

  &__item {
    margin-left: 5px;
    margin-bottom: 5px;
  }
}
</style>
