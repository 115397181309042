<template>
  <AppLayout>
    <div class="ContactsPage">
      <h1>Контакты 📮</h1>
      <div class="ContactsPage__list">
        <a href="https://vk.com/inmine" target="_blank" class="ContactsPage__card dark-card">
          <span class="ContactsPage__icon-holder">
            <ServerIndicator>
              <VkIcon class="ContactsPage__icon"/>
            </ServerIndicator>
          </span>
          <span class="ContactsPage__info">
            <span class="ContactsPage__label">
              Группа ВКонтакте
            </span>
            <span class="ContactsPage__link link link--primary">
              vk.com/inmine
            </span>
          </span>
        </a>
        <a href="https://t.me/inmine_ru" target="_blank" class="ContactsPage__card dark-card">
          <span class="ContactsPage__icon-holder">
            <ServerIndicator>
              <TelegramIcon class="ContactsPage__icon"/>
            </ServerIndicator>
          </span>
          <span class="ContactsPage__info">
            <span class="ContactsPage__label">
              Телеграм канал
            </span>
            <span class="ContactsPage__link link link--primary">
              t.me/inmine_ru
            </span>
          </span>
        </a>
        <a href="https://inmine.ru/discord" target="_blank" class="ContactsPage__card dark-card">
          <span class="ContactsPage__icon-holder">
            <ServerIndicator>
              <DiscordIcon class="ContactsPage__icon"/>
            </ServerIndicator>
          </span>
          <span class="ContactsPage__info">
            <span class="ContactsPage__label">
              Дискорд сервер
            </span>
            <span class="ContactsPage__link link link--primary">
              inmine.ru/discord
            </span>
          </span>
        </a>
        <a href="https://vk.me/inmine" target="_blank" class="ContactsPage__card dark-card">
          <span class="ContactsPage__icon-holder">
            <ServerIndicator>
              <VkIcon class="ContactsPage__icon"/>
            </ServerIndicator>
          </span>
          <span class="ContactsPage__info">
            <span class="ContactsPage__label">
              Поддержка ВКонтакте
            </span>
            <span class="ContactsPage__link link link--primary">
              vk.me/inmine
            </span>
          </span>
        </a>
        <a href="mailto:support@inmine.ru" target="_blank" class="ContactsPage__card dark-card">
          <span class="ContactsPage__icon-holder">
            <ServerIndicator>
              <EmailIcon class="ContactsPage__icon"/>
            </ServerIndicator>
          </span>
          <span class="ContactsPage__info">
            <span class="ContactsPage__label">
              Почта для вопросов об оплате
            </span>
            <span class="ContactsPage__link link link--primary">
              support@inmine.ru
            </span>
          </span>
        </a>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import ServerIndicator from "@/components/ServerIndicator";
import VkIcon from '@/assets/images/vk.svg?inline'
import EmailIcon from '@/assets/images/email.svg?inline'
import TelegramIcon from '@/assets/images/tg.svg?inline'
import DiscordIcon from '@/assets/images/discord.svg?inline'

export default {
  name: "ContactsPage",
  components: {ServerIndicator, AppLayout, VkIcon, EmailIcon, TelegramIcon, DiscordIcon}
}
</script>

<style lang="scss">
.ContactsPage {

  &__list {
    max-width: 785px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    @include mobile {
      grid-template-columns: 1fr;
      grid-gap: 4px;
    }
  }

  &__card {
    max-width: 100%;

    display: flex;
    align-items: center;

    @include PC {
      min-width: 385px;
    }
  }

  &__icon-holder {
    margin-right: 20px;

    @include mobile {
      margin-right: 12px;
    }
  }

  &__icon {
    filter: drop-shadow(0px 16px 32px rgba($primary, 0.32));
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__label {
    margin-bottom: 6px;
    font-size: 18px;
    font-weight: 500;

    @include mobile {
      margin-bottom: 4px;
      font-size: 14px;
    }
  }

  &__link {

    @include mobile {
      font-size: 14px;
    }
  }
}
</style>