<template>
  <AppLayout>
    <div class="DonatePage mobile-dark-block">
      <h1>Возможности привилегий 👑</h1>
      <div class="DonatePage__row hide-mobile">
        <p>Привилегия</p>
        <p>Возможности</p>
      </div>
      <div class="DonatePage__list" v-if="descriptions">
        <Privilege v-for="(description, key) in descriptions" v-bind="description" :key="key"/>
      </div>
      <div class="DonatePage__action hide-pc">
        <router-link to="/">
          <ButtonSecondary>
            Перейти к покупкам
          </ButtonSecondary>
        </router-link>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AppLayout";
import Privilege from "@/components/Privilege";
import ButtonSecondary from "@/components/ButtonSecondary";
import {mapActions, mapState} from "vuex";

export default {
  name: "DonatePage",
  components: {ButtonSecondary, Privilege, AppLayout},
  computed: {
    ...mapState('privileges', ['descriptions']),
  },
  methods: {
    ...mapActions('privileges', ['getDescriptions']),
  },
  created() {
    this.getDescriptions();
  }
}
</script>

<style lang="scss">
.DonatePage {

  @include mobile {
    margin-top: -8px;
    padding: 24px 20px 32px;
  }

  &__row {
    display: grid;
    grid-template-columns: 40% 1fr;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba($white, .04);

    font-size: 14px;
    color: $textGray;
  }

  &__list {
    @include mobile {
      margin-bottom: 24px;
      display: grid;
      grid-gap: 4px;
    }
  }

  &__action {
    button {
      width: 100%;
    }
  }
}
</style>