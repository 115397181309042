<template>
  <li class="OrderedListItem" v-if="isShow">
    <div class="OrderedListItem__marker">
      {{ marker }}
    </div>
    <div class="OrderedListItem__body">
      <div class="OrderedListItem__content" v-html="content"/>
      <div class="OrderedListItem__description" v-if="description" v-html="description"/>
    </div>
  </li>
</template>

<script>
export default {
  name: "OrderedListItem",
  props: {
    content: {
      type: String,
      required: true,
    },
    isShow: {
      type: Boolean,
    },
    description: {
      type: String,
    },
    marker: {
      type: String,
      required: true,
    },
  }
}
</script>

<style lang="scss">
.OrderedListItem {
  position: relative;

  & + & {
    margin-top: 20px;
  }

  &__marker {
    position: absolute;
    top: 0;
    left: 0;

    padding-top: 8px;
    max-width: 30px;

    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #E4E4E4;
  }

  &__body {
    padding-left: 40px;

    font-weight: normal;
    font-size: 16px;

    @include mobile {
      padding-left: 30px;
    }
  }

  &__content {
    line-height: 150%;
    color: $white;
  }

  &__description {
    margin-top: 4px;

    line-height: 120%;
    color: $textLightGray;
  }
}
</style>
