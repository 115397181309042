<template>
  <MainPageLayout>
    <div class="PaymentBannedPage">
      <NavBack to="servers">Другой сервер</NavBack>
      <TitleWithNicknameAndServer/>
      <div class="PaymentBannedPage__block mobile-dark-block dark-block" v-if="banInfo">
        <img class="PaymentBannedPage__icon" src="@/assets/images/payment-banned.png" alt="payment-banned">
        <div class="PaymentBannedPage__body">
          <h3 class="PaymentBannedPage__subtitle">Вы забанены на сервере</h3>
          <div class="PaymentBannedPage__info-block">
            <div class="PaymentBannedPage__row">
              <div class="PaymentBannedPage__label">Дата разбана</div>
              <div class="PaymentBannedPage__label mobile">до</div>
              <div class="PaymentBannedPage__value">{{ banInfo.unban | date }}</div>
            </div>
            <div class="PaymentBannedPage__row">
              <div class="PaymentBannedPage__label">Причина бана</div>
              <div class="PaymentBannedPage__label mobile">Причина:</div>
              <div class="PaymentBannedPage__value">{{ banInfo.reason }}</div>
            </div>
          </div>
          <ButtonSecondary @click.native="buy">
            Снять бан за {{ banInfo.price | price }}
          </ButtonSecondary>
          <hr class="PaymentBannedPage__line hide-pc">
        </div>
      </div>
    </div>
  </MainPageLayout>
</template>

<script>
import MainPageLayout from "@/layouts/MainPageLayout";
import NavBack from "@/components/NavBack";
import TitleWithNicknameAndServer from "@/components/TitleWithNicknameAndServer";
import ButtonSecondary from "@/components/ButtonSecondary";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "PaymentErrorPage",
  components: {ButtonSecondary, TitleWithNicknameAndServer, NavBack, MainPageLayout},
  created() {
    if (!this.banInfo) {
      this.$router.replace('/');
    }
  },
  computed: {
    ...mapGetters('privileges', ['privileges']),
    ...mapGetters('purchase', ['banInfo'])
  },
  methods: {
    buy() {
      this.setPrivilege({
        id: this.banInfo?.product,
        name: 'разбан',
        ...this.banInfo
      });
      this.$router.push({name: 'payment'})
    },
    ...mapMutations('purchase', ['setPrivilege']),
  }
}
</script>

<style lang="scss">
.PaymentBannedPage {

  .PaymentResultModule__icon {
    display: none;
  }

  &__icon {
    @include mobile {
      margin-bottom: 24px;
    }
  }

  &__block {
    max-width: 625px;
    padding: 40px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 40px;

    @include mobile {
      padding: 32px 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-gap: 0;

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: -42px;
    }
  }

  &__subtitle {
    margin-bottom: 12px;
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;

    @include mobile {
      text-align: center;
    }
  }

  &__body {
    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__info-block {
    margin-bottom: 24px;
  }

  &__row {
    display: flex;
    align-items: center;

    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: $textGray;

    @include PC {
      & + & {
        margin-top: 8px;
      }
    }

    @include mobile {
      display: block;
      text-align: center;

      * {
        display: inline;
      }
    }
  }

  &__label {
    margin-right: 24px;

    &.mobile {
      display: none;
    }

    @include mobile {
      display: none;
      margin: 0;

      &.mobile {
        display: inline-block;
        margin-right: 4px;
      }
    }
  }

  &__value {
    @include PC {
      color: $white;
    }
  }

  &__line {
    width: 100%;
    margin-top: 32px;
  }
}
</style>