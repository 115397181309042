import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from "@/pages/HomePage";
import ContactsPage from "@/pages/ContactsPage";
import DonatePage from "@/pages/DonatePage";
import ServersPage from "@/pages/ServersPage";
import PrivilegesPage from "@/pages/PrivilegesPage";
import PaymentPage from "@/pages/PaymentPage";
import PaymentResultPage from "@/pages/PaymentResultPage";
import PaymentBannedPage from "../pages/PaymentBannedPage";
import RulesPage from "@/pages/RulesPage";
import NotFoundPage from "@/pages/NotFoundPage";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage,
        meta: {
            title: 'Покупка доната на сервере Minecraft PE',
            description: 'Онлайн магазин сервера ИнМайн. Мы лучшие игровые сервера Майнкрафт на телефоне. Начни играть у нас уже сейчас!'
        }
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: ContactsPage,
        meta: {
            title: 'Контакты',
            description: "Связь с администрацией проекта"
        }
    },
    {
        path: '/donate',
        name: 'donate',
        component: DonatePage,
        meta: {
            title: 'Описание привилегий',
            description: "Описание всех привилегий, доступных к покупке"
        }
    },
    {
        path: '/rules',
        name: 'rules',
        component: RulesPage,
        meta: {
            title: 'Правила сервера',
        }
    },
    {
        path: '/servers',
        name: 'servers',
        component: ServersPage,
        meta: {
            title: 'Выбор сервера'
        }
    },
    {
        path: '/privileges',
        name: 'privileges',
        component: PrivilegesPage,
        meta: {
            title: 'Выбор привилегии'
        }
    },
    {
        path: '/payment',
        name: 'payment',
        component: PaymentPage,
        meta: {
            title: 'Выбор способа оплаты'
        }
    },
    {
        path: '/payment-receipt',
        name: 'payment-receipt',
        component: PaymentResultPage,
        meta: {
            title: 'Информация о платеже'
        }
    },
    {
        path: '/payment-banned',
        name: 'paymentBanned',
        component: PaymentBannedPage,
        meta: {
            title: 'Вы забанены на сервере'
        }
    },
    {
        path: '/error/404',
        name: 'NotFound',
        component: NotFoundPage,
        meta: {
            title: 'Страница не существует'
        }
    },
    {
        path: '*',
        redirect: '/error/404'
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
})

const TITLE_PREFIX = "InMine.ru — ";
const DEFAULT_TITLE = "Покупка доната на сервере Minecraft PE";
const DEFAULT_DESCRIPTION = "Онлайн магазин сервера ИнМайн. Мы лучшие игровые сервера Майнкрафт на телефоне. Начни играть у нас уже сейчас";

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = TITLE_PREFIX + (to.meta.title || DEFAULT_TITLE);
        document.querySelector("meta[name='description']").setAttribute("content", to.meta.description || DEFAULT_DESCRIPTION)
    });
});

export default router;

