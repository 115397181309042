<template>
  <MainPageLayout>
    <div class="ServersPage">
      <NavBack to="home">Другой никнейм</NavBack>
      <h1 class="mobile-small">
        {{ nickname }}, выберите сервер,<br class="hide-mobile">
        на котором хотите купить донат
      </h1>
      <div class="ServersPage__list">
        <template v-if="lastOnlineServers.length">
          <p class="ServersPage__label hide-pc">Последний раз вы играли здесь</p>
          <ServerCard
              v-for="(server) of lastOnlineServers"
              :server="server"
              :is-last="true"
              :key="server.port"
          />
        </template>
        <p class="ServersPage__label hide-pc" v-if="otherServers.length">Остальные сервера</p>
        <ServerCard
            v-for="(server) of otherServers"
            :server="server"
            :key="server.port"
        />
      </div>
    </div>
  </MainPageLayout>
</template>

<script>
import MainPageLayout from "@/layouts/MainPageLayout";
import NavBack from "@/components/NavBack";
import purchaseProgressController from "@/mixins/purchaseProgressController";
import {mapState} from "vuex";
import ServerCard from "@/components/ServerCard";

export default {
  name: "ServersPage",
  mixins: [purchaseProgressController],
  components: {ServerCard, NavBack, MainPageLayout},
  computed: {
    lastOnlineServers() {
      return this.servers?.filter((server) => server?.id === this.lastServer) ?? [];
    },
    otherServers() {
      return this.servers?.filter((server) => server?.id !== this.lastServer) ?? [];
    },
    ...mapState('purchase', ['nickname']),
    ...mapState('privileges', ['servers', 'lastServer']),
  },
}
</script>

<style lang="scss">
.ServersPage {

  &__label {
    margin-bottom: 8px;

    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: $textGray;

    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;

    @include mobile {
      display: flex;
      flex-direction: column;
      grid-gap: 0;
    }
  }
}
</style>
