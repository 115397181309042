import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        nickname: '',
        server: null,
        privilege: null,
        payment: null,
        method: null
    },
    mutations: {
        setNickname(s, nickname) {
            s.nickname = nickname;
        },
        setServer(s, server) {
            s.server = server;
        },
        setPrivilege(s, privilege) {
            s.privilege = privilege;
        },
        setPayment(s, payment) {
            s.payment = payment
        },
        setMethod(s, method) {
            s.method = method
        }
    },
    actions: {
        setMethod(s, method) {
            s.commit('setMethod', method)
        },
        setPayment(s, payment) {
            s.commit('setPayment', payment)
        },

        async processPayment(ctx, email) {
            const {state: s} = ctx;

            let {
                data,
                status
            } = await Vue.axios.post('/orders/?login=' + s.nickname + '&product=' + s.privilege?.id + '&server=' + s.server?.id + '&method=' + s.method +'&payment=' + s.payment + '&email=' + email, {}, {
                headers: {'Content-Type': 'application/json'}
            });

            if (status === 200 && data.type === 'redirect') {
                location.href = data.href;
                return true;
            }
            throw data;
        }
    },
    getters: {
        banInfo(s) {
            return s.server?.is_ban;
        },
        nicknameIsValid(s) {
            return !!s.nickname;
        },
        getBuyLink(s) {
            return s.link
        }
    }
}
