<template>
  <header class="Header">
    <LogoIcon/>
    <div class="Header__blackout" :class="{'Header__blackout--active': open}"></div>
    <nav
        class="Header__nav"
        :class="{'Header__nav--open': open}"
    >
      <div class="Header__mobile-header-top hide-pc">
        <LogoIcon/>
        <div class="mobile-button" @click="open = false">
          <CloseMenuIcon/>
        </div>
      </div>
      <RouterLink :to="{name: 'home'}" exact-active-class="Header__item--active" class="Header__item hide-pc">
        <HomeIcon/>
        <div class="Header__link-text">
          Главная
        </div>
      </RouterLink>
      <RouterLink :to="{name: 'donate'}" exact-active-class="Header__item--active" class="Header__item">
        <PrivilegesIcon/>
        <div class="Header__link-text">
          Описание привилегий
        </div>
      </RouterLink>
      <RouterLink :to="{name: 'rules'}" exact-active-class="Header__item--active" class="Header__item">
        <RulesIcon/>
        <div class="Header__link-text">
          Правила
        </div>
      </RouterLink>
      <a href="https://vk.com/inmine" target="_blank" class="Header__item">
        <SocialIcon/>
        <div class="Header__link-text">
          Мы ВКонтакте
        </div>
      </a>
      <RouterLink :to="{name: 'contacts'}" exact-active-class="Header__item--active" class="Header__item">
        <ContactsIcon/>
        <div class="Header__link-text">
          Контакты
        </div>
      </RouterLink>
    </nav>
    <div class="mobile-button hide-pc" @click="open = !open">
      <OpenMenuIcon/>
    </div>
  </header>
</template>

<script>

import LogoIcon from "@/modules/app/LogoIcon";
import PrivilegesIcon from '@/assets/images/header/privileges.svg?inline'
import RulesIcon from '@/assets/images/header/rules.svg?inline'
import SocialIcon from '@/assets/images/header/social.svg?inline'
import ContactsIcon from '@/assets/images/header/contacts.svg?inline'
import HomeIcon from '@/assets/images/header/home.svg?inline'
import OpenMenuIcon from '@/assets/images/header/open-mobile-menu.svg?inline'
import CloseMenuIcon from '@/assets/images/close-modal-mobile.svg?inline'

export default {
  name: "Header",
  components: {
    LogoIcon,
    PrivilegesIcon,
    RulesIcon,
    SocialIcon,
    ContactsIcon,
    HomeIcon,
    OpenMenuIcon,
    CloseMenuIcon,
  },
  data: () => ({
    open: false,
  }),
}
</script>

<style lang="scss">
.Header {
  padding-top: 32px;
  margin-bottom: 80px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    padding-top: 20px;
    margin-bottom: 32px;
  }

  &__blackout {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
    transform: translateY(-100%);
    opacity: 0;
    transition: all 0.25s, transform 0.1s linear 0.25s;

    &--active {
      transform: translateY(0);
      opacity: 1;
      transition: all 0.25s, transform 0s;
      backdrop-filter: blur(5px);
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    @include mobile {
      padding: 20px 20px 16px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 200;
      transform: translateY(-100%);
      transition: transform 250ms;

      align-items: stretch;
      flex-direction: column;
      background: $bgGray;
      border-radius: 0 0 16px 16px;

      &--open {
        transform: translateY(0);
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;

    & + & {
      @include PC {
        margin-left: 40px;
      }
    }

    @include mobile {
      height: 56px;
      border-top: solid 1px rgba($white, .04);

      &--active {
        svg path {
        stroke: $textLightGray;
        }
      }
    }

    svg path {
      transition: stroke 250ms;
    }

    &:hover {
      svg path {
        stroke: $textLightGray;
      }
    }

    &--active, &:hover {
      .Header__link-text {
        color: $white;
      }
    }
  }

  &__link-text {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 600;
    color: $textLightGray;
    transition: color 250ms;

    @include mobile {
      padding-left: 16px;
    }
  }

  &__mobile-header-top {
    padding-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
