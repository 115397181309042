<template>
  <MainPageLayout hide-mobile>
    <div class="PaymentPage mobile-dark-block">
      <NavBack back>Другая привилегия</NavBack>
      <h1 v-if="privilege && server">
        Купить
        <template v-if="privilege.type === 'group'">статус</template>
        <template v-if="privilege.unban">статус</template>
        {{ privilege.name }} за<span class="nbsp"></span>{{ privilege.price | price }}<br
          class="hide-mobile">
        <span class="PaymentPage__title-fade">
          на сервере {{ server.id }} на ник {{ nickname }}
        </span>
      </h1>
      <div class="PaymentPage__step" v-if="settings.isEmail">
        <div class="PaymentPage__header">
          <div class="PaymentPage__number">1</div>
          <div class="PaymentPage__label">Ваша электронная почта для чека</div>
        </div>
        <div class="PaymentPage__body">
          <Input
              v-model="email"
              placeholder="admin@inmine.ru"
              :check-mark="validEmail"
          />
        </div>
      </div>
      <div class="PaymentPage__step" v-if="settings.isPaymentMethod">
        <div class="PaymentPage__header">
          <div class="PaymentPage__number"></div>
          <div class="PaymentPage__label">Выберите способ оплаты</div>
        </div>
        <div class="PaymentPage__body">
          <div class="PaymentPage__card-list">
            <PaymentType
                v-for="method in payments" :key="method.code + '-' + method.name"
                v-bind="method"
                @click="selectPaymentHandle(method.code, method.payment)"
            />
          </div>
        </div>
      </div>
    </div>
  </MainPageLayout>
</template>

<script>
import MainPageLayout from "@/layouts/MainPageLayout";
import purchaseProgressController from "@/mixins/purchaseProgressController";
import NavBack from "@/components/NavBack";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Input from "@/components/Input";
import PaymentType from "@/components/PaymentType";
import validator from 'validator'


const EMAIL_KEY = 'email-inmine';

export default {
  mixins: [purchaseProgressController],
  name: "PaymentPage",
  components: {PaymentType, Input, NavBack, MainPageLayout},
  data: () => ({
    email: localStorage.getItem(EMAIL_KEY) ?? '',
    emailErrorNotificationIds: [],
  }),
  computed: {
    validEmail() {
      if (!this.settings.isEmail) return true;
      return validator.isEmail(this.email);
    },
    ...mapState('payments', ['payments']),
    ...mapGetters('privileges', ['privileges']),
    ...mapState('privileges', ['settings']),
    ...mapState('purchase', ['nickname', 'server', 'privilege']),
  },
  methods: {
    selectPaymentHandle(method = 'bank_card', payment) {
      if (this.validEmail) {
        localStorage.setItem(EMAIL_KEY, this.email);
        this.setMethod(method)
        this.setPayment(payment)
        this.processPayment(this.email).then(({link}) => {
          if(link === true) {
            this.pushError('Переходим к оплате...');
          }
        }).catch(err => {
          this.pushError(err);
        });
      } else {
        this.pushError('Укажите почту, чтобы получить чек').then((id) => {
          this.emailErrorNotificationIds.push(id);
        });
      }
    },
    ...mapActions('payments', ['getPayments']),
    ...mapActions('purchase', ['processPayment', 'setMethod', 'setPayment']),
    ...mapActions('notifications', ['pushError']),
    ...mapMutations('notifications', ['removeNotification'])
  },
  created() {
    if (!this.privilege || !this.server) {
      this.$router.push('/');
    } else if (!this.settings?.isEmail && !this.settings?.isPaymentMethod) {
      this.selectPaymentHandle();
    }
    if (!this.payments.length) {
      this.getPayments();
    }
  },
  watch: {
    email() {
      if (this.validEmail) {
        this.emailErrorNotificationIds.forEach(this.removeNotification);
        this.emailErrorNotificationIds = [];
      }
    }
  }
}
</script>

<style lang="scss">
.PaymentPage {

  @include mobile {
    padding-top: 24px;
    padding-bottom: 32px;
  }

  &__title-fade {
    color: $textGray;
  }

  &__card-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 16px;

    @include mobile {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 8px;
    }
  }

  &__step {
    position: relative;

    @include PC {
      padding-left: 80px;
    }

    &:last-of-type .PaymentPage__number::before {
      content: '2';
    }

    &:first-of-type .PaymentPage__number::before {
      content: '1';
    }

    & + & {
      margin-top: 40px;

      @include mobile {
        margin-top: 24px;
      }
    }
  }

  &__body .Input {
    max-width: 384px;
  }

  &__header {
    height: 40px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;

    @include mobile {
      height: auto;
      font-size: 14px;
    }
  }

  &__label {
    font-size: 18px;
    font-weight: 600;

    @include mobile {
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__number {
    @include PC {
      position: absolute;
      left: 0;
      top: 0;
      height: 40px;
      width: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 16px;
      font-weight: 600;
      background: rgba($white, .04);
      box-shadow: 0 12px 24px rgba($white, .04);
      border-radius: 4px;
    }

    @include mobile {
      font-weight: 500;
      color: $textGray;
      margin-right: 4px;

      &::after {
        content: '.';
      }
    }
  }
}
</style>
