<template>
  <a :href="link">
    <slot/>
  </a>
</template>

<script>
export default {
  name: "AddServer",
  props: {
    port: {
      default: 19132
    }
  },
  computed: {
    link() {
      return `minecraft://?addExternalServer=InMine.ru|inmine.ru:${this.port}`;
    }
  }
}
</script>