<template>
  <div class="dark-block">
    <div class="HowToDonate">
      <h2 class="HowToDonate__title">
        Как купить донат<br>
        на ИнМайне? 👑
      </h2>
      <a :href="linkVideo" target="_blank" class="HowToDonate__video-preview">
        <div class="HowToDonate__play"/>
        <div class="HowToDonate__learn-time-text">
          Узнать за 1 минуту
        </div>
      </a>
      <div class="HowToDonate__open-instruction">
        <ButtonSecondary @click="open = true">
          Читать инструкцию, ~2 мин.
        </ButtonSecondary>
      </div>
    </div>
    <HowToDonateModal :open="open" @close="open = false"/>
  </div>
</template>

<script>
import ButtonSecondary from "@/components/ButtonSecondary";
import HowToDonateModal from "@/modules/modals/HowToDonateModal";

export default {
  name: "HowToDonate",
  components: {HowToDonateModal, ButtonSecondary},
  data: () => ({
    open: false,
  }),
  computed: {
    linkVideo() {
      //return `https://api.inmine.ru/links/guide`;
      return `https://youtu.be/JuGyc4BJLKg`;
    }
  }
}
</script>

<style lang="scss">
.HowToDonate {
  padding: 40px;

  @include mobile {
    padding: 24px 0 0;
  }

  &__title {
    @include PC {
      text-align: center;
    }
  }

  &__video-preview {
    margin: 24px -40px;
    height: 220px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    background-image: url("~@/assets/images/bg/home-to-donate.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    transition: 250ms;

    &:hover {
      background-image: url("~@/assets/images/bg/how-to-donate-hover.png");
    }

    @include mobile {
      margin: 20px -20px 16px;
    }

    &:hover {
      .HowToDonate__play {
        box-shadow: 0 16px 40px rgba(243, 63, 63, 0.48);
      }
      .HowToDonate__learn-time-text {
        color: rgba($white, .48);
      }
    }
  }

  &__play {
    height: 72px;
    width: 72px;

    background-color: $primary;
    border-radius: 50%;
    flex-shrink: 0;

    background-image: url("~@/assets/images/play-video.png");
    background-repeat: no-repeat;
    background-position: center;

    box-shadow: 0 16px 24px rgba(243, 63, 63, 0.32);
    transition: box-shadow 250ms;

    @include mobile {
      height: 56px;
      width: 56px;
    }
  }

  &__learn-time-text {
    margin-top: 16px;

    font-size: 16px;
    font-weight: 400;
    color: rgba($white, .24);

    transition: 250ms;

    @include mobile {
      margin-top: 20px;
      font-size: 14px;
    }
  }

  &__open-instruction {
    display: flex;
    justify-content: center;

    @include mobile {
      button {
        width: 100%;
      }
    }
  }
}
</style>