import Vue from 'vue'
import Vuex from 'vuex'
import purchase from "@/store/purchase";
import servers from "@/store/servers";
import privileges from "@/store/privileges";
import discount from "@/store/discount";
import accounts from "@/store/accounts";
import payments from "@/store/payments";
import rules from "@/store/rules";
import notifications from "@/store/notifications";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        purchase,
        servers,
        privileges,
        discount,
        accounts,
        payments,
        rules,
        notifications,
    }
})

export default store;

