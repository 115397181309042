<template>
  <div class="HowToDoModalStep" :class="{'HowToDoModalStep--active': isActive}">
    <div class="HowToDoModalStep__main">
      <p class="HowToDoModalStep__step-count" :class="{'hide-pc': !number}">
        <StepModalCount :number="number" :steps="steps"/>
      </p>
      <h5 class="HowToDoModalStep__title">
        {{ title }}
      </h5>
      <slot name="main"/>
    </div>
    <div
        class="HowToDoModalStep__image-block"
        :class="{'HowToDoModalStep__image-block--wide': wideImage}"
    >
      <div class="HowToDoModalStep__image-holder">
        <slot name="image"/>
      </div>
      <div class="HowToDoModalStep__about hide-mobile">
        <slot name="donate"/>
      </div>
    </div>
    <slot name="other"/>
    <div class="HowToDoModalStep__controller hide-pc" v-if="!hideController">
      <ButtonOutline large @click="prevStepHandle" :disabled="first">
        Назад
      </ButtonOutline>
      <ButtonSecondary @click="nextStepHandle">
        {{ last ? 'Закрыть окно' : 'Дальше →' }}
      </ButtonSecondary>
    </div>
  </div>
</template>

<script>
import StepModalCount from "@/components/StepModalCount";
import ButtonOutline from "@/components/ButtonOutline";
import ButtonSecondary from "@/components/ButtonSecondary";

export default {
  name: "HowToDoModalStep",
  components: {ButtonSecondary, ButtonOutline, StepModalCount},
  model: {
    prop: 'activeIndex',
    event: 'change'
  },
  props: {
    title: String,
    hideController: Boolean,
    number: Number,
    steps: Number,
    activeIndex: Number,
    wideImage: Boolean,
    first: Boolean,
    last: Boolean,
  },
  computed: {
    isActive() {
      return this.activeIndex === this.number;
    },
  },
  methods: {
    nextStepHandle() {
      if (this.last) {
        this.$emit('close');
      } else {
        this.$emit('change', this.activeIndex + 1);
      }
    },
    prevStepHandle() {
      if (this.first) {
        this.$emit('close');
      } else {
        this.$emit('change', this.activeIndex - 1);
      }
    }
  }
}
</script>

<style lang="scss">
.HowToDoModalStep {
  padding: 32px 0;

  position: relative;

  border-top: solid 1px $bgLightGray;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: $textLightGray;

  @include mobile {
    padding: 0 20px 20px;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $bgDark;

    overflow-y: auto;
    flex-direction: column;
    display: none;

    &--active {
      display: flex;
    }
  }

  &__main {
    max-width: 600px;
  }

  &__step-count {
    margin-bottom: 12px;

    @include mobile {
      position: absolute;
      left: 20px;
      top: 25px;
      z-index: 5;
    }
  }

  &__title {
    margin-bottom: 12px;
    font-size: 28px;
    font-weight: 700;
    line-height: 120%;
    color: $white;
  }

  &__image-block {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 32%;
    grid-column-gap: 32px;

    &--wide {
      grid-template-columns: 1fr;
    }

    @include mobile {
      position: relative;
      display: block;
      order: -1;
      margin: 0 -20px;
      margin-bottom: 24px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 56px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }

  &__image-holder {
    display: flex;
    border-radius: 4px;
    overflow: hidden;

    @include mobile {
      border-radius: 0;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 212px;

      @include mobile {
        height: 190px;
      }
    }
  }

  &__controller {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      height: 44px;
    }
  }
}
</style>