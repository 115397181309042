<template>
  <div class="ServerIndicator" :class="{'ServerIndicator--large': large}">
    <div class="ServerIndicator__number">
      <slot/>
    </div>
    <div class="ServerIndicator__indicator" v-if="showIndicator">
      <div class="ServerIndicator__mark" :style="markStyle"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServerIndicator",
  props: {
    large: Boolean,
    indicatorValue: Number,
    showIndicator: Boolean,
  },
  computed: {
    markStyle() {
      return {
        height: `${100 - this.indicatorValue * 100}%`
      }
    }
  }
}
</script>

<style lang="scss">
.ServerIndicator {
  height: 44px;
  width: 44px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  background: rgba($primary, 0.08);
  box-shadow: 0 12px 24px rgba($primary, 0.12);
  border-radius: 2px;
  transform: skewX(-5deg);

  &--large {
    @include PC {
      height: 54px;
      width: 54px;

      .ServerIndicator__number {
        font-size: 28px;
      }
    }
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 900;
    color: $primary;
    text-shadow: 0 17px 20px rgba(225, 49, 49, 0.25),
    0 5px 4px rgba(225, 49, 49, 0.1),
    0 1px 1px rgba(225, 49, 49, 0.06);
  }

  &__indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 4px;

    display: flex;
    align-items: flex-end;
    background: rgba($primary, 0.16);
  }

  &__mark {
    height: 100%;
    width: 100%;

    background-color: $primary;
    box-shadow: 0 8px 12px rgba($primary, 0.48);
  }
}
</style>