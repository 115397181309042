import Vue from 'vue';
import Vapi from "vuex-rest-api"

export default new Vapi({
    axios: Vue.axios,
    state: {
        discount: []
    }
})
    .get({
        action: 'getDiscount',
        property: 'discount',
        path: '/discount/',
        onSuccess(s, {data}) {
            s.discount = data;
        }
    })
    .getStore({namespaced: true});
