import {mapGetters, mapState} from "vuex";

export default {
    computed: {
        ...mapState('purchase', ['server', 'privilege']),
        ...mapGetters('purchase', ['nicknameIsValid']),
    },
    created() {
        const routeName = this.$route.name;
        const redirect = name => this.$router.push({name});

        if (!this.nicknameIsValid && routeName !== 'home') {
            redirect('home');

            if (!this.server && routeName !== 'servers') {
                redirect('servers');

                if (!this.privilege && routeName !== 'privileges') {
                    redirect('privileges');
                }
            }
        }
    }
}