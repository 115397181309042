<template>
  <div class="PaymentCard dark-card" @click="$emit('click')">
    <div class="PaymentCard__image-holder">
      <img :src="image" :alt="name">
    </div>
    <div class="PaymentCard__name">
      {{name}}
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentType",
  props: {
    id: String,
    name: String,
    image: String,
  }
}
</script>

<style lang="scss">
.PaymentCard {
  display: flex;
  flex-direction: column;
  padding: 0;
  cursor: pointer;
  max-width: 144px;

  @include mobile {
    background-color: $bgGray;

    &, &:hover {
      box-shadow: none;
    }
  }

  &__image-holder {
    padding: 0 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;

    background-color: $bgLightGray;
    border-radius: 0 0 4px 4px;

    img {
      max-height: 100%;
    }

    @include mobile {
      padding: 5px;
    }
  }

  &__name {
    height: 46px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    font-size: 14px;
    font-weight: 400;
    color: $textLightGray;
    padding: 5px 10px;
  }
}
</style>