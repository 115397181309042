<template>
  <MainPageLayout hide-mobile v-if="!pending.order">
    <div class="PaymentSuccessPage" v-if="isSuccess">
      <PaymentSuccessModule
          :title="title"
          subtitle="Донат выдан"
      />
    </div>
    <div class="PaymentErrorPage" v-else>
      <PaymentResultModule
          error
          title="Проблема с оплатой"
          subtitle="Платеж не прошел оплату. Попробуйте еще раз."
          secondtitle="Также возможно, что платежная система еще не обработала ваш платеж. Попробуйте обновить страницу через 5 минут."
      >
        <template #icon>
          <img src="@/assets/images/payment-error.png" alt="payment-error">
        </template>
        <template #body v-if="order">
          <PaymentCheck>
            <div class="PaymentErrorPage__info-title">
              Если оплатить все равно не получается
            </div>
            <ol class="PaymentErrorPage__info-block" type="1">
              <li class="PaymentErrorPage__info-row">
                <span class="PaymentErrorPage__info-row-number">1.</span>
                <div class="PaymentErrorPage__info-text">
                  Сделайте скриншот страницы
                </div>
              </li>
              <li class="PaymentErrorPage__info-row">
                <span class="PaymentErrorPage__info-row-number">2.</span>
                <div class="PaymentErrorPage__info-text">
                  Отправьте его нам <a href="https://vk.me/inmine" target="_blank"
                                       class="link link--primary nowrap">в<span
                    class="nbsp"></span>техподдержку</a>
                  и опишите проблему
                </div>
              </li>
            </ol>
          </PaymentCheck>
        </template>
      </PaymentResultModule>
    </div>
  </MainPageLayout>
</template>

<script>
import MainPageLayout from "@/layouts/MainPageLayout";
import PaymentSuccessModule from "@/modules/payment/PaymentSuccessModule";
import {mapActions, mapState} from "vuex";
import PaymentCheck from "@/modules/payment/PaymentCheck";
import PaymentResultModule from "@/modules/payment/PaymentResultModule";

export default {
  name: "PaymentSuccessPage",
  components: {PaymentSuccessModule, MainPageLayout, PaymentCheck, PaymentResultModule},
  created() {
    this.getOrder({params: this.$route.query.order});
  },
  computed: {
    isSuccess() {
      return this.order?.status === 1;
    },
    title() {
      if ((this.order?.product).toLowerCase() === 'разбан') {
        return 'Вы разбанены.<br> Можно заходить на<span class="nbsp"></span>сервер!';
      } else {
        return `Вы купили ${this.order?.product}!`;
      }
    },
    ...mapState('payments', ['order', 'pending']),
  },
  methods: {
    ...mapActions('payments', ['getOrder']),
  }
}
</script>

<style lang="scss">
.PaymentSuccessPage {

}

.PaymentErrorPage {
  margin-top: -20px;

  &__info-title {
    font-size: 18px;
    font-weight: 700;
    color: $white;

    @include mobile {
      font-size: 16px;
    }
  }

  &__info-block {
    padding-top: 16px;

    @include mobile {
      padding-top: 12px;
    }

    &, .link {
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;

      @include mobile {
        font-size: 14px;
      }
    }
  }

  &__info-row {
    display: flex;

    & + & {
      margin-top: 16px;

      @include mobile {
        margin-top: 12px;
      }
    }
  }

  &__info-row-number {
    color: $textGray;
  }

  &__info-text {
    padding-left: 15px;
    color: $white;

    &, .link {

    }
  }
}
</style>