import Vue from 'vue'
import axios from "axios";
import VueAxios from "vue-axios";

function getDataErr(res) {
    if (!res) return false;
    return ['data', 'message'].reduce((tmp, key) =>
            tmp[key] ? tmp[key] : tmp
        , res);
}

export let initAxios = (urlApi) => {
    Vue.use(VueAxios, axios);

    Vue.axios.defaults.baseURL = urlApi;
    Vue.axios.defaults.headers['Content-Type'] = 'x-www-form-urlencoded';

    Vue.axios.interceptors.response.use(function (res) {
        if (res.status !== 200) return Promise.reject(`Error status #${res.status}`);
        if (!res.data || (res.data.success && res.data.success !== true)) return Promise.reject(getDataErr(res));

        if (res.data?.info) res.data = res.data.info;
        if (res.data?.data) res.data = res.data.data;
        return res;
    }, function (err) {
        console.warn(err);
        return Promise.reject(getDataErr(err.response) || err.toString());
    });
    /*Vue.axios.interceptors.response.use(null, function (err,a,b,c) {
        store.commit('notifications/putNotification', {message: err.message});
        return err;
    });*/
}