import Vue from 'vue';
import Vapi from "vuex-rest-api"

const store = new Vapi({
    axios: Vue.axios,
    state: {
        settings: {
            isEmail: true,
            isPaymentMethod: true,
        },
        donaters: [],
        servers: [],
        lastServer: null
    }
})
    .get({
        action: 'getPrivileges',
        property: 'privileges',
        path: ({nick}) => `/products/${nick}/`,
        beforeRequest(s) {
            s.servers = [];
            s.lastServer = null;
        },
        onSuccess(s, {data}) {
            s.servers = data.servers;
            s.lastServer = data.last_server;
        }
    })
    .get({
        action: 'getDescriptions',
        property: 'descriptions',
        path: '/products/descriptions/',
        onSuccess(s, {data}) {
            s.descriptions = data;
        }
    })
    .get({
        action: 'getDonaters',
        property: 'donaters',
        path: '/orders/latest/',
        onSuccess(s, {data}) {
            s.donaters = data;
        }
    })
    .getStore({namespaced: true});

export default {
    ...store,
    getters: {
        ...store.getters,
        privileges(s, g, gs) {
            return gs.purchase.server?.products;
        },
    }
}
/*
{
    name: 'Флай',
    price: 8,
    statingPrice: 9,
},
*/
