const NICK_STORAGE = 'possible_nicknames';
const NICK_STORAGE_LIMIT = 3;

export function addNickStorage(nick) {
    nick = nick?.trim();
    const list = getStorage(NICK_STORAGE);
    if (nick && !list.includes(nick)) {
        list.push(nick);
        if (list.length > NICK_STORAGE_LIMIT) {
            list.shift();
        }
        setStorage(NICK_STORAGE, list);
    }
}

export function getNickStorage(empty = []) {
    return getStorage(NICK_STORAGE, empty);
}

function getStorage(name, defaultVal = []) {
    try {
        return JSON.parse(localStorage.getItem(name)) ?? defaultVal;
    } catch (e) {
        return defaultVal;
    }
}

function setStorage(name, val) {
    try {
        return localStorage.setItem(name, JSON.stringify(val));
    } catch (e) {
        console.warn(e);
    }
}