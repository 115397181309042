<template>
  <div class="PageNav">
    <ul class="PageNav__list">
      <li class="PageNav__list-item" v-for="(name, index) in list" :key="index">
        <a :href="`#${name}`">
          {{ name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PageNav",
  props: {
    list: {
      type: Array,
      required: true,
    },
  }
}
</script>

<style lang="scss">
.PageNav {

  @include laptop {
    @include outContainer;
    overflow-x: auto;
    padding-bottom: 12px;
    margin-bottom: -12px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include laptop {
      width: max-content;
      flex-direction: row;
    }
  }

  &__list-item {
    border-bottom: 1px dashed rgba($white, 0.12);

    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    color: #E4E4E4;

    cursor: pointer;
    transition: border-bottom-color 250ms;

    &:hover {
      border-bottom-color: rgba($white, 0.24);
    }

    @include laptop {
      white-space: nowrap;
    }

    & + & {
      margin-top: 12px;

      @include laptop {
        margin-top: 0;
        margin-left: 24px;
      }
    }
  }
}
</style>
