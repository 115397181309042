import Vue from 'vue';
import Vapi from "vuex-rest-api"

export default new Vapi({
    axios: Vue.axios,
    state: {
        accounts: [],
        reviews: [],
    }
})
/*    .get({
        action: 'getAccounts',
        property: 'accounts',
        path: '/accounts/',
        onSuccess(s, {data}) {
            s.accounts = data?.accounts ?? [];
        }
    })*/
    .get({
        action: 'getReviews',
        property: 'reviews',
        path: '/etc/reviews/',
        onSuccess(s, {data}) {
            s.reviews = data ?? [];
        }
    })
    .getStore({namespaced: true});
