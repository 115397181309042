<template>
  <button
      class="ButtonOutline"
      :class="{'ButtonOutline--large': large}"
      v-bind="$attrs"
      @click="$emit('click')"
  >
    <slot/>
    <ArrowIcon v-if="arrow"/>
  </button>
</template>

<script>

import ArrowIcon from '@/assets/images/button-outline-arrow.svg?inline'

export default {
  name: "ButtonOutline",
  components: {ArrowIcon},
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss">
.ButtonOutline {
  height: 21px;
  padding: 0 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: none;
  border: solid 1px rgba($primary, .16);
  border-radius: 2px;
  color: $primary;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  transition: all 250ms;

  &:hover {
    border-color: rgba($primary, .4);
  }

  &:active {
    border-color: rgba($primary, .24);
  }

  &:disabled {
    border-color: rgba($primary, .08);
    color: rgba($primary, 0.48);
  }

  &--large {
    height: 44px;
    padding: 0 20px;
    box-shadow: 0 12px 24px rgba($white, 0.04);
    border: 1px solid rgba($white, 0.04);
    border-radius: 4px;

    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $white;

    @include mobile {
      box-shadow: none;
    }

    &:hover {
      border-color: rgba($white, .24);
    }

    &:active {
      border-color: rgba($white, .12);
    }

    &:disabled {
      color: $textGray;
      border-color: rgba($white, .04);
    }
  }
}
</style>