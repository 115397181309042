<template>
  <div class="PaymentCheck mobile-dark-block">
    <div class="PaymentCheck__body">
      <div class="PaymentCheck__title">
        Информация о покупке
      </div>
      <div class="PaymentCheck__row">
        <div class="PaymentCheck__label">
          Никнейм
        </div>
        <div class="PaymentCheck__value">
          {{ order.login }}
        </div>
      </div>
      <div class="PaymentCheck__row">
        <div class="PaymentCheck__label">
          Товар
        </div>
        <div class="PaymentCheck__value">
          {{ order.product }}
        </div>
      </div>
      <div class="PaymentCheck__row">
        <div class="PaymentCheck__label">
          Сервер
        </div>
        <div class="PaymentCheck__value">
          {{ order.server }}
        </div>
      </div>
      <div class="PaymentCheck__row">
        <div class="PaymentCheck__label">
          Стоимость
        </div>
        <div class="PaymentCheck__value">
          {{ order.price | price }}
        </div>
      </div>
      <div class="PaymentCheck__row">
        <div class="PaymentCheck__label">
          Дата
        </div>
        <div class="PaymentCheck__value">
          {{ order.date }}
        </div>
      </div>
      <div class="PaymentCheck__row">
        <div class="PaymentCheck__label">
          Номер платежа
        </div>
        <div class="PaymentCheck__value">
          #{{ order.order_id }}
        </div>
      </div>
    </div>
    <div class="PaymentCheck__additional-info">
      <slot/>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import {mapActions, mapState} from "vuex";

export default {
  name: "PaymentCheck",
  computed: {
    ...mapState('payments', ['order']),
  },
  methods: {
    getDateString(date) {
      return moment(date).calendar();
    },
    ...mapActions('payments', ['getOrder']),
  }
}
</script>

<style lang="scss">
.PaymentCheck {
  padding: 40px 48px;
  background: $bgDark;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);

  display: flex;
  justify-content: space-between;
  align-items: center;

  @include PC {
    border-radius: 8px;

  }

  @include mobile {
    padding: 24px 20px 32px;
    flex-direction: column;
  }


  &__body {
    width: 100%;
    margin-right: 40px;

    @include mobile {
      margin-right: 0;
      padding-bottom: 16px;
      border-bottom: 1px solid rgba($white, .04);
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    color: $white;

    @include mobile {
      margin-bottom: 12px;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 40% 1fr;
    grid-column-gap: 16px;

    font-size: 16px;
    font-weight: 400;
    line-height: 130%;

    @include mobile {
      font-size: 14px;
    }

    & + & {
      margin-top: 12px;

      @include mobile {
        margin-top: 8px;
      }
    }
  }

  &__label {
    color: $textLightGray;
  }

  &__value {
    color: $white;
  }

  &__additional-info {
    flex-shrink: 0;

    @include PC {
      width: 340px;
      padding: 32px;

      background: $bgLightGray;
      box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
    }
  }
}
</style>