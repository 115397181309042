<template>
  <MainPageLayout>
    <div class="PrivilegesPage">
      <NavBack to="servers">Другой сервер</NavBack>
      <TitleWithNicknameAndServer />
      <SaleCountdown class="hide-mobile" />
      <div class="PrivilegesPage__mobile-block mobile-dark-block">
        <StatusInfo :donate="donateCurrent" class="hide-pc" />
        <div class="PrivilegesPage__list">
          <PrivilegeCard
           v-for="(privilege, index) in list"
           :name="privilege.name"
           :price="privilege.price"
           :starting-price="privilege.old_price"
           :key="index"
           @click="selectPrivilege(privilege)"
          />
        </div>
        <hr class="PrivilegesPage__mobile-line hide-pc">
      </div>
    </div>
  </MainPageLayout>
</template>

<script>
import MainPageLayout from "@/layouts/MainPageLayout";
import purchaseProgressController from "@/mixins/purchaseProgressController";
import { mapActions, mapMutations } from "vuex";
import SaleCountdown from "@/components/SaleCountdown";
import PrivilegeCard from "@/components/PrivilegeCard";
import NavBack from "@/components/NavBack";
import TitleWithNicknameAndServer from "@/components/TitleWithNicknameAndServer";
import StatusInfo from "@/components/StatusInfo";

export default {
  mixins: [purchaseProgressController],
  name: "PrivilegesPage",
  components: { StatusInfo, TitleWithNicknameAndServer, NavBack, PrivilegeCard, SaleCountdown, MainPageLayout },
  computed: {
    list() {
      return this.privileges.filter(({ price }) => {
        if (price <= 0) {
          return false
        }
        return true;
      });
    },
    donateCurrent() {
      return this.server?.donate;
    },
    privileges() {
      return this.server?.products ?? [];
    },
  },
  methods: {
    selectPrivilege(privilege) {
      this.setPrivilege(privilege)
      this.$router.push({ name: 'payment' })
    },
    ...mapMutations('purchase', ['setPrivilege']),
    ...mapActions('privileges', ['getPrivileges']),
  },
}
</script>

<style lang="scss">
.PrivilegesPage {

  .SaleCountdown {
    @include mobile {
      margin-bottom: 24px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;

    @include mobile {
      padding-top: 16px;
      grid-template-columns: 1fr;
      grid-gap: 4px;
    }
  }

  &__mobile-block {
    @include mobile {
      margin-bottom: -48px;
      padding: 24px 20px 4px;

      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__mobile-line {
    @include mobile {
      margin: 32px 0;
    }
  }
}
</style>
