<template>
  <button
      class="ButtonSecondary"
      :class="{'ButtonSecondary--small': small}"
      v-bind="$attrs"
      @click="$emit('click')"
  >
    <slot/>
  </button>
</template>

<script>
export default {
  name: "ButtonSecondary",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss">
.ButtonSecondary {
  height: 48px;
  padding: 0 20px;

  box-shadow: 0 12px 24px rgba($primary, 0.08);
  background: rgba($primary, 0.16);
  color: $primary;
  font-size: 16px;
  font-weight: 600;
  transition: all 250ms;

  @include mobile {
    height: 43px;
    font-size: 14px;
    line-height: 17px;
  }

  &:hover {
    box-shadow: 0 12px 24px rgba($primary, 0.16);
    background: rgba($primary, 0.24);
  }

  &:active {
    box-shadow: 0 12px 24px rgba($primary, 0);
  }

  &:disabled {
    background: rgba($white, 0.04);
    color: rgba($white, 0.24);
    box-shadow: none;
  }

  &--small {
    height: 23px;
    padding: 0 6px;
    font-size: 14px;
    font-weight: 400;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }
}
</style>